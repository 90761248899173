import './about.module.css';
import PageTitle from '../../components/page-title/pageTitle';

//Страница "Почему мы"
const About = () => {
    return (
        <div className="about">
            <PageTitle title="Почему мы" bottomText="Главная / Почему мы" />
            <article
                id="post-7532"
                className="static-page_text">
                <div className="entry-content">
                    <p className="static-page_text-plain">
                        Компания "UltraNout" — это поставщик редкой эксклюзивной
                        техники из США и Европы. Наш ассортимент включает в себя
                        весь премиальный сегмент рынка мобильных компьютеров, а
                        именно такие бренды, как Razer, Alienware, Microsoft,
                        Samsung, LG, Clevo, Lenovo, Dell, Asus, HP и многие
                        другие!
                    </p>
                    <p className="static-page_text-plain">
                        В шоуруме нашего розничного представителя можно
                        посмотреть и сравнить лучшие модели самых известных
                        линеек ThinkPad, Precision, Yoga, Spectre, Alienware,
                        XPS, Razer, Samsung Galaxy, LG Gram, Zenbook Pro/Duo
                    </p>
                    <p className="static-page_text-plain">НАШИ ПЛЮСЫ:</p>
                    <ul>
                        <li className="static-page_text-list-item">
                            100% клиентоориентированность, наша цель – ДОВОЛЬНЫЙ
                            ПОКУПАТЕЛЬ. Читайте отзывы о нас: на{' '}
                            <a href="https://yandex.ru/maps/org/ultra_nout/169046270181/reviews/?ll=37.592833%2C55.794143&z=10">
                                Яндекс.Картах
                            </a>{' '}
                            и{' '}
                            <a href="https://market.yandex.ru/business--ultra-nout/13529706/reviews">
                                Яндекс.Маркете
                            </a>
                        </li>
                        <li className="static-page_text-list-item">
                            Профессиональные консультации по всем последним
                            новинкам и лучшим хитам предыдущих лет в чате и по
                            телефону: +7 (499) 110-77-67
                        </li>
                        <li className="static-page_text-list-item">
                            Возможность заказать любую модель из США или Европы
                            с полным сервисом, бесплатной годовой гарантией,
                            пост-гарантийным обслуживанием и полной русификацией
                            продукта в случае необходимости (лазерная гравировка
                            русских букв заводского качества с сохранением
                            подсветки). Гарантия безопасности сделки!
                        </li>
                        <li className="static-page_text-list-item">
                            Бесплатная гарантия от нашего сервисного центра в
                            соответствии с законодательством РФ на все
                            поставляемое оборудование – 1 год
                        </li>
                        <li className="static-page_text-list-item">
                            Модернизация любой сложности по цене комплектующих
                            для всего поставляемого нами оборудования
                        </li>
                        <li className="static-page_text-list-item">
                            Лазерная гравировка русских букв на клавиатуре
                            заводского качества, русификация Windows
                        </li>
                        <li className="static-page_text-list-item">
                            ДОСТАВКА ПО ВСЕЙ СТРАНЕ, включая сервис “безопасная
                            сделка”, а также для городов Санкт-Петербург,
                            Воронеж, Минск (Белоруссия), Казань, Екатеринбург,
                            Симферополь и Севастополь доступна ОПЛАТА ПОСЛЕ
                            ПОЛУЧЕНИЯ, услуга платная (для других городов
                            уточняйте).
                        </li>
                    </ul>
                    <p className="static-page_text-plain">
                        Цена предлагаемых нами товаров складывается из стоимости
                        оригинального, НЕ китайского товара, логистики в наше
                        непростое время, стоимости сервиса и обслуживания по
                        гарантии – мы боремся за качество и ваше спокойствие.
                        Скидки – возможны и обсуждаются индивидуально!
                    </p>
                    <h4
                        style={{
                            textAlign: 'center',
                            fontWeight: 700,
                            fontSize: '20px',
                        }}>
                        <cite>
                            Задайте нам вопрос: +7 (499) 110-77-67;
                            ultranout@gmail.com
                        </cite>
                    </h4>
                </div>
            </article>
        </div>
    );
};

export default About;
