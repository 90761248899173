import { Link } from 'react-router-dom';
import './SearchBarProductCard.css'

export const SearchBarProductCard = ({ product, cancelFocusManually }) => {
    return (
        <div
            className="autocomplete-suggestion"
            onClick={() => cancelFocusManually(false)}>
            <Link
                to={`/product/${product.sku}`}
                style={{
                    display: 'flex',
                    textDecoration: 'none',
                    color: 'black',
                }}>
                <img
                    src={product.images[0].src}
                    alt="Фото продукта в результатах поиска"
                    style={{
                        width: '25%',
                        objectFit: 'contain',
                    }}
                />
                <div
                    className="autocomplete-suggestion_text"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                    <h4 style={{ fontSize: '14px' }}>{product.name}</h4>
                    <p style={{ fontSize: '12px', marginBottom: '0' }}>
                        Артикул: {product.slug}
                    </p>
                    <div className="product-card_text-price crossed">
                        <del
                            style={{
                                color: 'rgb(245 102 34)',
                                fontSize: '14px',
                                fontWeight: '600',
                            }}>
                            <span style={{ color: 'black' }}>
                                {product.regularPrice} &#8381;
                            </span>
                        </del>
                    </div>
                    <div
                        className="product-card_text-price discount"
                        style={{
                            color: 'rgb(245 102 34)',
                            fontSize: '14px',
                            fontWeight: '600',
                        }}>
                        {product.salePrice} &#8381;
                    </div>
                </div>
            </Link>
        </div>
    );
};
