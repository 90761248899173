import '../styles/App.css';
import MainPage from './main-page';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import About from './about';
import Catalogue from './catalogue';
import Header from '../components/header/header';
import StoreRules from './store-rules';
import PaymentOptions from './payment-options';
import Warranty from './warranty';
import DeliveryMethods from './delivery-options';
import Reviews from './reviews';
import ContactUs from './contact-us';
import Footer from '../components/footer/footer';
import Wishlist from './wishlist';
import Cart from './cart';
import Compare from './compare';
import Checkout from './checkout';
import ProductPage from './product-page';
import NoMatch from './no-match';
import CheckoutSuccess from './checkout-success';
// import Ultraguide from './Ultraguide/Ultraguide';
import ClearCartAfterSuccessfulOnlinePayment from '../helpers/ClearCartAfterSuccessfulOnlinePayment';
import useScript from '../helpers/hooks/useScript';
import { Options } from './options';
import { YandexOauth } from './yandex-oauth';
import { useLayoutEffect } from 'react';

const Layout = () => {
    return (
        <div>
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

function App() {
    useScript('//code.jivo.ru/widget/q7JJRcQQKa');

    const location = useLocation();

    // scroll to top of page after a page transition.
    useLayoutEffect(() => {
        document.documentElement.scrollTo({ top:0, left:0, behavior: "instant" });
    }, [location.pathname]);

    if (!localStorage.getItem('wishlist')) {
        localStorage.setItem('wishlist', '[]');
    }

    if (!localStorage.getItem('catalogue')) {
        localStorage.setItem('catalogue', '[]');
    }

    if (!localStorage.getItem('cart')) {
        localStorage.setItem('cart', '[]');
    }

    if (!localStorage.getItem('compare')) {
        localStorage.setItem('compare', '[]');
    }

    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<MainPage />} />
                    <Route path="about" element={<About />} />
                    <Route
                        exact
                        path="catalogue/:category?"
                        element={<Catalogue />}
                    />
                    {/*<Route path="ultraguide" element={<Ultraguide />} />*/}
                    <Route path="product/:sku" element={<ProductPage />} />
                    <Route path="wishlist" element={<Wishlist />} />
                    <Route path="storeRules" element={<StoreRules />} />
                    <Route path="paymentOptions" element={<PaymentOptions />} />
                    <Route path="warranty" element={<Warranty />} />
                    <Route
                        path="deliveryMethods"
                        element={<DeliveryMethods />}
                    />
                    <Route path="reviews" element={<Reviews />} />
                    <Route path="options" element={<Options />} />
                    <Route path="contactUs" element={<ContactUs />} />
                    <Route path="cart" element={<Cart />} />
                    <Route path="compare" element={<Compare />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route
                        path="checkout/success/:orderId"
                        element={<CheckoutSuccess />}
                    />
                    <Route
                        path="checkout/onlinePaymentSuccess"
                        element={<ClearCartAfterSuccessfulOnlinePayment />}
                    />

                    <Route path="yandex-oauth" element={<YandexOauth />} />

                    {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
            {/*<ScrollRestoration/>*/}
        </div>
    );
}

export default App;
