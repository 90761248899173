import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as yup from 'yup';

const phoneNumberRegEx =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const schema = yup.object().shape({
    name: yup.string().required('Это обязательное поле'),
    phone: yup
        .string()
        .required('Это обязательное поле')
        .matches(phoneNumberRegEx, 'Неправильный номер телефона'),
});

export const ModalInstantPurchase = ({ show, setShowModal, product, onConfirm }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (values, formikHelpers) => {
        setIsLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/emails/instantPurchase`, {
                data: {
                    info: values,
                    product: product,
                },
            })
            .then((response) => {
                alert(response.data);
                setIsLoading(false);
                response.status === 200 && setShowModal(false);
            });
        formikHelpers.setSubmitting(false);
    }

    return (
        <Modal show={show} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Купить в один клик!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{
                        name: '',
                        phone: '',
                    }}
                    onSubmit={(values,formikHelpers) => handleSubmit(values, formikHelpers)}
                    validationSchema={schema}>
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          /* and other goodies */
                      }) => (
                        <Form
                            onBlur={handleBlur}
                            noValidate={true}
                            onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Ваше имя</Form.Label>
                                        <Form.Control
                                            placeholder="Имя"
                                            name="name"
                                            value={values.name}
                                            disabled={isLoading}
                                            onChange={
                                                handleChange
                                            }></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Телефон</Form.Label>
                                        <Form.Control
                                            type="phone"
                                            placeholder="Телефон"
                                            name="phone"
                                            value={values.phone}
                                            disabled={isLoading}
                                            onChange={handleChange}
                                            isValid={
                                                touched.phone && !errors.phone
                                            }
                                            isInvalid={
                                                !!errors.phone
                                            }></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Button type="submit" variant="dark" style={{width: '100%'}}>
                                            {isLoading ? <Spinner size='sm'/> : 'Отправить'}
                                        </Button>
                                        <Form.Label
                                            style={{
                                                fontSize: '0.875em',
                                                color: 'gray',
                                            }}>
                                            Мы перезвоним в рабочие дни с 10:00 до 21:00. В выходные дни - с 10:00 до 18:00
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            {/*<Modal.Footer bsPrefix="modal-footer">*/}
            {/*    <div id="modal-container" style={{width: '170px'}}>*/}

            {/*    </div>*/}
            {/*    <Button*/}
            {/*        bsPrefix="btn button_main_color-rounded"*/}
            {/*        onClick={() => setShowModal(false)}>*/}
            {/*        Отмена*/}
            {/*    </Button>*/}
            {/*</Modal.Footer>*/}
        </Modal>
    );
};
