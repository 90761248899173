import { Link, useNavigate } from 'react-router-dom';
import {
    Badge,
    Button,
    CloseButton,
    FormControl,
    InputGroup,
    Spinner,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCartShopping,
    faMagnifyingGlass,
    faShuffle,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from '../../helpers/hooks/useDebounce';
import { SearchBarProductCard } from '../search-bar-product-card/searchBarProductCard';
import axios from 'axios';
import { faX } from '@fortawesome/free-solid-svg-icons/faX';

const HeaderSearch = () => {
    const [badges, setBadges] = useState({
        wishlist: JSON.parse(localStorage.getItem('wishlist')).length,
        cart: JSON.parse(localStorage.getItem('cart')).length,
        compare: JSON.parse(localStorage.getItem('compare')).length,
    });
    const searchResultRef = useRef(null);

    const navigate = useNavigate();

    const [searchResult, setSearchResult] = useState([]);
    const [showSearchResult, setShowSearchResult] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const DEBOUNCE_DELAY = 500;
    const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DELAY);

    useEffect(() => {
        /**
         * Отменяем показ результата поиска по клику за пределами дива
         */
        function handleClickOutside(event) {
            if (
                searchResultRef.current &&
                !searchResultRef.current.contains(event.target)
            ) {
                setShowSearchResult(false);
            }
        }

        // Привязываем event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Отвязываем event listener для очистки
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchResultRef]);

    useEffect(() => {
        // console.log(debouncedSearchTerm);
        if (debouncedSearchTerm !== '') {
            axios
                .get(`${process.env.REACT_APP_API_URL}/products`, {
                    params: {
                        parameters: {
                            per_page: 12,
                            page: 1,
                            searchString: debouncedSearchTerm,
                        },
                    },
                })
                .then((response) => {
                    // console.log(response.data);
                    setShowSearchResult(true);
                    setSearchResult(response.data.rows);
                });
        } else {
            setSearchResult([]);
            setShowSearchResult(false);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        const onStorage = () => {
            setBadges({
                wishlist: JSON.parse(localStorage.getItem('wishlist')).length,
                cart: JSON.parse(localStorage.getItem('cart')).length,
                compare: JSON.parse(localStorage.getItem('compare')).length,
            });
        };

        window.addEventListener('storage', onStorage);

        return () => {
            window.removeEventListener('storage', onStorage);
        };
    }, []);

    const handleClearSearch = () => {
        setSearchTerm('');
        setSearchResult([]);
    };

    return (
        <div className="header-search">
            <Link to="/" className="site-logo">
                <img
                    style={{ display: 'inline-block' }}
                    className="site-logo"
                    src={`${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2021/09/new-logo.jpeg`}
                    alt=""
                />
            </Link>
            <div
                className="search-group"
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl
                        autocomplete='off'
                        id="search"
                        placeholder="Поиск товара"
                        type="search"
                        value={searchTerm}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                navigate(
                                    `/catalogue?searchString=${searchTerm}`,
                                );
                                setShowSearchResult(false);
                            }
                        }}
                        onFocus={() => {
                            if (searchResult.length > 0) {
                                setShowSearchResult(true);
                            }
                        }}
                    />
                    <button
                        onClick={handleClearSearch}
                        type="button"
                        className="btn bg-transparent"
                        style={{ marginLeft: '-40px', zIndex: 100 }}>
                        <FontAwesomeIcon icon={faX}></FontAwesomeIcon>
                    </button>
                    <Button
                        bsPrefix="btn button_main_color"
                        onClick={() => {
                            navigate(`/catalogue?searchString=${searchTerm}`);
                            setShowSearchResult(false);
                        }}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </Button>
                </InputGroup>
                <div
                    className="search-result"
                    ref={searchResultRef}
                    style={{
                        position: 'absolute',
                        top: '75%',
                        width: '100%',
                        zIndex: 1,
                        maxHeight: '300px',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'stretch',
                        margin: '0',
                        backgroundColor: 'white',
                        overflow: 'hidden',
                        overflowY: 'auto',
                    }}
                    onFocus={() => console.log('focus')}
                    onBlur={() => {
                        console.log('blur');
                        setShowSearchResult(false);
                        searchResultRef.current.blur();
                    }}>
                    {showSearchResult && (
                        <>
                            {searchResult.map((product) => (
                                <SearchBarProductCard
                                    product={product}
                                    cancelFocusManually={setShowSearchResult}
                                />
                            ))}
                            <div
                                className="view-all-results"
                                onClick={() => {
                                    navigate(
                                        `/catalogue?searchString=${searchTerm}`,
                                    );
                                    setShowSearchResult(false);
                                }}>
                                <span>СМОТРЕТЬ ВСЕ РЕЗУЛЬТАТЫ</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="header-search_icons">
                <Link className="header-social_links-icon" to="/wishlist">
                    <Badge pill bg="ultranout_color">
                        {badges.wishlist}
                    </Badge>
                    <FontAwesomeIcon icon={faHeart} size="xl" />
                </Link>
                <Link className="header-social_links-icon" to="/compare">
                    <Badge pill bg="ultranout_color">
                        {badges.compare}
                    </Badge>
                    <FontAwesomeIcon icon={faShuffle} size="xl" />
                </Link>
                <Link className="header-social_links-icon" to="/cart">
                    <Badge pill bg="ultranout_color">
                        {badges.cart}
                    </Badge>
                    <FontAwesomeIcon icon={faCartShopping} size="xl" />
                </Link>
            </div>
        </div>
    );
};

export default HeaderSearch;
