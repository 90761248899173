import { Button, Modal, Spinner } from 'react-bootstrap';

export const ModalOnConfirmOrder = ({ show }) => {
  return (
    <Modal show={show} centered>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
    </Modal>
  );
};