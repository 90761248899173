//Фильтры для каталога
import React, { useEffect, useState } from 'react';
import { Accordion, Col, Form, FormCheck, Row } from 'react-bootstrap';
import styles from './filters-sidebar.module.css'
import './track-styles.css'
import { useFilters } from '../../helpers/hooks/useFilters';
import { SliderFilter } from '../slider-filter';

const FiltersSidebar = ({
    defaultPrices,
    defaultWeight,
    products,
    category,
    onFilterChange,
    selectedFilters,
}) => {
    const [filters, setFilters] = useFilters(
        products,
        category,
        selectedFilters,
    );
    const [prices, setPrices] = useState(defaultPrices);
    const [weight, setWeight] = useState(defaultWeight);
    console.log(defaultWeight)

    useEffect(() => {
        setPrices(defaultPrices);
    }, [defaultPrices]);

    useEffect(() => {
        setWeight(defaultWeight);
    }, [defaultWeight]);

    return (
        <div className={styles.filtersWrapper}>
            <SliderFilter
                name={"Цена"}
                minDistance={10000}
                step={1000}
                maxPlaceholder={"Максимальная цена"}
                minPlaceholder={"Минимальная цена"}
                values={prices}
                onChange={(event) => {
                    setPrices([event.target.value, prices[1]]);
                    onFilterChange('Цена', [event.target.value, prices[1]]);
                }}
                onChange1={(event) => {
                    setPrices([prices[0], event.target.value]);
                    onFilterChange('Цена', [prices[0], event.target.value]);
                }}
                onAfterChange={() => onFilterChange('Цена', prices)}
                defaultValues={defaultPrices}
                onChange2={(values) => setPrices(values)}
                onAfterChange1={() => onFilterChange('Цена', prices)}
            />
            {filters.map((filter, filterIndex) =>
                filter.databaseName !== 'Вес' ? (
                    <Accordion
                        className={styles.accordion}
                        defaultActiveKey={
                            filter.alwaysOpenDropdown ? filterIndex : null
                        }
                        flush
                        bsPrefix="accordion ultranout-catalogue-accordion"
                        alwaysOpen>
                        <Accordion.Item eventKey={filterIndex.toString()}>
                            <Accordion.Header>
                                {filter.frontendAlias.toUpperCase()}
                            </Accordion.Header>
                            <Accordion.Body className={styles.filterBody}>
                                {filter?.values?.map((value, valueIndex) => (
                                    <FormCheck
                                        key={value + valueIndex}
                                        type="checkbox"
                                        label={value}
                                        style={{
                                            fontSize: '0.875rem',
                                            color:
                                                value.substring(
                                                    value.length - 3,
                                                    value.length,
                                                ) !== '(0)'
                                                    ? 'initial'
                                                    : 'gray',
                                        }}
                                        value={value}
                                        checked={selectedFilters.find((item) =>
                                            item.values.includes(
                                                value
                                                    .replace(/\([^()]*\)/g, '')
                                                    .trim(),
                                            ),
                                        )}
                                        onChange={() =>
                                            onFilterChange(
                                                filter.databaseName,
                                                value
                                                    .replace(/\([^()]*\)/g, '')
                                                    .trim(),
                                            )
                                        }
                                    />
                                ))}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                ) : (
                  <SliderFilter
                      name={"Вес"}
                      minDistance={0.1}
                      step={0.1}
                      maxPlaceholder={"Максимальный вес"}
                      minPlaceholder={"Минимальный вес"}
                      values={weight}
                      onChange={(event) => {
                          setWeight([
                            event.target.value,
                            weight[1],
                          ]);
                        onFilterChange('Вес', [
                            event.target.value,
                            weight[1],
                        ]);
                      }}
                      onChange1={(event) => {
                          setWeight([
                              weight[0],
                              event.target.value,
                          ]);
                      onFilterChange('Вес', [
                            weight[0],
                            event.target.value,
                          ]);
                      }}
                      onAfterChange={() =>
                          onFilterChange('Вес', weight)}
                      defaultValues={defaultWeight}
                      onChange2={(values) => setWeight(values)}
                      onAfterChange1={() =>
                          onFilterChange('Вес', weight)}
                  />
                ),
            )}
        </div>
    );
};

export default FiltersSidebar;
