import AddressForm from '../../components/address-form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ModalOnConfirmOrder } from '../../components/modal-on-confirm-order/ModalOnConfirmOrder';

const paymentMethods = [
    {
        id: 'cheque',
        title: 'Оплата наличными при получении в Москве',
        description:
            'Ввиду сложившейся ситуации актуальность цен и резерв просьба подтверждать у менеджера с помощью звонка или через электронный чат',
    },
    {
        id: 'lifepay',
        title: 'Оплата картой любого банка на сайте через защищенный протокол',
        description:
            'Приём платежей обеспечивает платёжная платформа Lifepay',
    },
    // {
    //     id: 'paykeeper',
    //     title: 'Оплата картой любого банка на сайте через защищенный протокол',
    //     description:
    //         'Приём платежей обеспечивает платёжная платформа Paykeeper',
    // },
]

const Checkout = () => {
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [showModal, setShowModal] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [selectedMethod, setSelectedMethod] = useState('cheque');

    useEffect(() => {
        const cartId = sessionStorage.getItem('cart_id');

        axios.get('https://login.yandex.ru/info?', {
            headers: {
                Authorization: `OAuth ${cartId}`
            }
        })
            .then(response => {
                console.log(response.data.first_name)
                setFormValues({
                    ...formValues,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    email: response.data.emails[0],
                    phoneNumber: response.data.default_phone.number,
                })
            })
    },[])

    const navigate = useNavigate();

    const handlePaymentRequest = (formValues) => {
        setShowModal(true);
        const orderBody = {
            paymentMethod: selectedMethod,
            paymentMethodTitle: selectedMethod.title,
            total: cart.reduce(
                (total, product) =>
                    total + (selectedMethod === 'cheque' ? product.salePrice : product.regularPrice) * product.quantity,
                0,
            ),
            customerNote: formValues.details,
            shippingInfo: {
                name: formValues.firstName,
                lastName: formValues.lastName,
                address: formValues.address,
                city: formValues.city,
                phoneNumber: formValues.phoneNumber,
                email: formValues.email,
            },
            products: cart.map(product => {
                return {
                    ...product,
                    price: selectedMethod === 'cheque' ? product.salePrice : product.regularPrice,
                    quantity: product.quantity
                }
            })
        };
        // console.log(orderBody);
        axios
            .post(`${process.env.REACT_APP_API_URL}/orders/create`, { data: orderBody })
            .then((response) => {
                setShowModal(false);
                if (selectedMethod === 'cheque') {
                    navigate(`/checkout/success/${response.data}`);
                    setCart([]);
                    localStorage.setItem('cart', JSON.stringify([]));
                } else {
                    window.location.href = response.data;
                }
            })
            .catch((error) => console.log(error));
    };

    return (
        <div
            className="checkout"
            style={{
                display: 'flex',
            }}>
            <ModalOnConfirmOrder show={showModal} />
            <AddressForm
                products={cart}
                paymentMethods={paymentMethods}
                initialValues={formValues}
                setFormValues={setFormValues}
                selectedMethod={selectedMethod}
                setSelectedMethod={setSelectedMethod}
                onSubmitOrder={handlePaymentRequest}
            />
        </div>
    );
};

export default Checkout;
