import { Button, Modal } from 'react-bootstrap';

export const ModalOnDeleteFromCart = ({ show, setShowModal, deleteFromCart }) => {
  return (
    <Modal show={show} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Вы действительно хотите удалить этот товар?</Modal.Title>
      </Modal.Header>
      <Modal.Footer bsPrefix="modal-footer">
        <Button
          bsPrefix="btn button_wrapper"
          onClick={() => setShowModal(false)}>
          <u>Отмена</u>
        </Button>
          <Button
            bsPrefix="btn button_main_color-rounded"
            onClick={deleteFromCart}>
            Удалить
          </Button>
      </Modal.Footer>
    </Modal>
  );
};