import { useEffect } from 'react';

export function YandexOauth() {
    useEffect(() => {
        window.YaSendSuggestToken(
            `${process.env.REACT_APP_PUBLIC_URL}`,
            {
                flag: true
            }
        )
    })

    return(
        <></>
    )
}
