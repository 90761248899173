import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import {
    faCheck,
    faHeart as faHeartSolid,
    faShuffle,
} from '@fortawesome/free-solid-svg-icons';
import { InstantPurchaseButton } from '../instant-purchase-button';
import styles from './product-buttons.module.css'
import "@tcb-web/create-credit/button"

const ProductButtons = ({
    verticalMode,
    product,
    inCart,
    onClickCart,
    onInstantClick,
    inWishlist,
    onClickWishlist,
    isInCompare,
    onClickCompare,
}) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.productButtons}>
                <div
                    className="product-card_text-cart-button"
                    style={{ paddingRight: '0.375rem' }}>
                    {inCart ? (
                        <Button
                            bsPrefix="btn button_main_color-rounded"
                            onClick={onClickCart}>
                            В КОРЗИНУ
                        </Button>
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip3">
                                    Перейти в корзину
                                </Tooltip>
                            }>
                            <Link to="/cart">
                                <Button bsPrefix="btn button_secondary_color">
                                    В корзине
                                </Button>
                            </Link>
                        </OverlayTrigger>
                    )}
                </div>
                <div
                    className="product-card_text-cart-wishlist-button"
                    style={{ paddingRight: '0.375rem' }}>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            inWishlist ? (
                                <Tooltip id="tooltip1">
                                    В список желаемого
                                </Tooltip>
                            ) : (
                                <Tooltip id="tooltip1">
                                    Убрать из желаемого
                                </Tooltip>
                            )
                        }>
                        <Button
                            bsPrefix="btn button_secondary_color"
                            onClick={onClickWishlist}>
                            {inWishlist ? (
                                <FontAwesomeIcon icon={faHeart} />
                            ) : (
                                <FontAwesomeIcon icon={faHeartSolid} />
                            )}
                        </Button>
                    </OverlayTrigger>
                </div>
                <div className="product-card_text-cart-wishlist-button">
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            isInCompare ? (
                                <Tooltip id="tooltip1">Сравнить</Tooltip>
                            ) : (
                                <Tooltip id="tooltip1">
                                    Убрать из сравнения
                                </Tooltip>
                            )
                        }>
                        <Button
                            bsPrefix="btn button_secondary_color"
                            onClick={onClickCompare}>
                            {isInCompare ? (
                                <FontAwesomeIcon icon={faShuffle} />
                            ) : (
                                <FontAwesomeIcon icon={faCheck} />
                            )}
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
            <div className={styles.instantPurchase}>
                <InstantPurchaseButton product={product} onClick={onInstantClick} />
            </div>
        </div>
    );
};

export default ProductButtons;
