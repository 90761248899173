import './noMatch.css';

const NoMatch = () => {
    return (
        <div className="no-match">
            <div className="no-match_main-content">
                <h3 className="no-match_header top" style={{fontSize: '45px'}}>ОЙ, ЧТО-ТО ПОШЛО НЕ ТАК...</h3>
            </div>
            <div className="no-match_additional">
                <h3 className="no-match_header bottom" style={{textAlign: 'center'}}>
                    К сожалению, запрашиваемая страница была перемещена или
                    удалена.
                </h3>
            </div>
        </div>
    );
};

export default NoMatch;
