import { useEffect, useState } from 'react';
import axios from 'axios';

export const useMinMaxPrice = () => {
  const [prices, setPrices] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/products/minMaxPrice`).then((response) => {
        console.log(response.data);
        setPrices(response.data);
    });
  }, [])

  return [prices, setPrices];
};
