import PageTitle from '../../components/page-title/pageTitle';
import { useEffect, useState } from 'react';
import ProductCard from '../../components/product-card';
import { Button, CloseButton, Table } from 'react-bootstrap';
import { handleWishlistClick } from '../../helpers/handleWishlistClick';
import handleCartClick from '../../helpers/handleCartClick';
import { handleCompareClick } from '../../helpers/handleCompareClick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

const Compare = () => {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [wishlist, setWishlist] = useState(
      JSON.parse(localStorage.getItem('wishlist')),
    );
    const navigate = useNavigate();

    // Эффект для получения списка продуктов из localStorage при монтировании компонента
    useEffect(() => {
        const compare = JSON.parse(localStorage.getItem('compare')) || [];
        setProducts(compare);
    }, []);

    return (
        <>
            <PageTitle title="Сравнение" bottomText="Главная / Сравнение" />
            {
              products.length > 0 ?
                <Table className="compare-products_wrapper" bordered responsive>
                    <tbody>
                        {/*Я так и не придумал как сделать это все за один map, но по хорошему надо придумать*/}
                        <tr>
                            <td></td>
                            {products.length &&
                                products.map((product) => (
                                    <td style={{ width: '33.75rem', textAlign: 'center' }}>
                                        <label
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                handleCompareClick(
                                                    product,
                                                    products,
                                                    setProducts,
                                                )
                                            }>
                                            <CloseButton />
                                            Удалить
                                        </label>
                                        <ProductCard
                                            product={product}
                                            verticalMode={true}
                                            hideDescription={true}
                                            onWishlistClick={() =>
                                                handleWishlistClick(
                                                    product,
                                                    wishlist,
                                                    setWishlist,
                                                )
                                            }
                                            onCartClick={() =>
                                                handleCartClick(
                                                    product,
                                                    cart,
                                                    setCart,
                                                )
                                            }
                                            isInWishlist={
                                                !wishlist.some(
                                                    (wishlistProduct) =>
                                                        wishlistProduct.id ===
                                                        product.id,
                                                )
                                            }
                                            isInCart={
                                                !cart.some(
                                                    (cartProduct) =>
                                                        cartProduct.id ===
                                                        product.id,
                                                )
                                            }
                                            isInCompare={
                                                !products.some(
                                                    (compareProduct) =>
                                                        compareProduct.id ===
                                                        product.id,
                                                )
                                            }
                                            onCompareClick={() =>
                                                handleCompareClick(
                                                    product,
                                                    products,
                                                    setProducts,
                                                )
                                            }
                                        />
                                    </td>
                                ))}
                        </tr>
                        <tr>
                            <td style={{ width: '33.75rem' }}>{'Описание'}</td>
                            {products.length &&
                                products.map((product) => (
                                    <td
                                        dangerouslySetInnerHTML={{
                                            __html: product.description,
                                        }}
                                        style={{ width: '33.75rem' }}></td>
                                ))}
                        </tr>
                        <tr>
                            <td style={{ width: '33.75rem' }}>
                                {'Арт'.toUpperCase()}
                            </td>
                            {products.length &&
                                products.map((product) => (
                                    <td style={{ width: '33.75rem' }}>
                                        {product.sku}
                                    </td>
                                ))}
                        </tr>
                        <tr>
                            <td style={{ width: '33.75rem' }}>
                                {'Доступность'.toUpperCase()}
                            </td>
                            {products.length &&
                                products.map((product) => (
                                    <td style={{ width: '33.75rem' }}>
                                        {product.stockQuantity <= 0
                                            ? 'Доступно для предзаказа'
                                            : `В наличии: ${product.stockQuantity}`}
                                    </td>
                                ))}
                        </tr>
                    </tbody>
                </Table>
                :
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '1rem', gap: '0.5rem'}}>
                  <FontAwesomeIcon icon={faShuffle} size={'10x'} style={{color: 'gainsboro'}}/>
                  <h1>Список сравнения пуст</h1>
                  <label>Вы можете найти подходящие для сравнения товары на странице "Каталог"</label>
                  <Button bsPrefix="btn button_main_color-rounded" style={{width: '12rem', height: '3rem'}} onClick={() => navigate('/catalogue')}>
                    {'Вернуться в магазин'.toUpperCase()}
                  </Button>
                </div>
            }
        </>
    );
};

export default Compare;
