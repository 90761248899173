import { useEffect, useState } from 'react';
import axios from 'axios';
import Filters from '../Filters';

export const useFilters = (
    products,
    category,
    selectedFilters,
) => {
    const [filters, setFilters] = useState(Filters);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/products/attributes`, {
                params: {
                    category: category,
                    filters: selectedFilters,
                },
            })
            .then((response) => {
                // console.log(response.data);
                let newFilters = JSON.parse(JSON.stringify(Filters));
                console.log(Filters);
                response.data.forEach((element) => {
                    const indexToUpdate = newFilters.findIndex(
                        (filter) => filter.databaseName === element.name,
                    );
                    if (indexToUpdate !== -1) {
                        newFilters[indexToUpdate].values.push(
                            element.value + ` (${element.count})`,
                        );
                    }
                    // console.log(newFilters[indexToUpdate]);
                });
                // console.log(newFilters);
                setFilters(newFilters);
            });
    }, [category, selectedFilters]);

    return [filters, setFilters];
};
