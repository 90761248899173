import { useEffect, useState } from 'react';
import axios from 'axios';

export const useProducts = (setIsLoading, category, parameters, debouncedFilters) => {
    const [products, setProducts] = useState([]);
    const [productsTotal, setProductsTotal] = useState(0);

    // console.log('useEffect' + ' ' + JSON.stringify(parameters));

    useEffect(() => {
        setIsLoading(true);
        let abortToAvoidRaceCondition = false;
        axios
            .get(`${process.env.REACT_APP_API_URL}/products`, {
                params: {
                    category: category,
                    parameters: parameters,
                    filters: debouncedFilters,
                },
            })
            .then((response) => {
                if (!abortToAvoidRaceCondition) {
                    setProducts([...response.data.rows]);
                    setProductsTotal(response.data.count);
                    setIsLoading(false);
                    localStorage.setItem(
                        'catalogue',
                        JSON.stringify(response.data),
                    );
                }
            });
        return () => (abortToAvoidRaceCondition = true);
    }, [category, parameters, debouncedFilters]);

    return { products, productsTotal };
};
