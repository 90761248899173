const YandexMap = () => {
    return (
        <div style={{position: 'relative', overflow: 'hidden'}}><a
            href="https://yandex.kz/maps/org/ultra_nout/169046270181/?utm_medium=mapframe&utm_source=maps"
            style={{color:'#eee', fontSize: '12px', position: 'absolute', top:'0px'}}>Ультра-ноут</a><a
            href="https://yandex.kz/maps/213/moscow/category/computer_store/184105760/?utm_medium=mapframe&utm_source=maps"
            style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}>Компьютерный магазин в Москве</a><a
            href="https://yandex.kz/maps/213/moscow/category/computers_and_computer_components_wholesale/184105764/?utm_medium=mapframe&utm_source=maps"
            style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '28px'}}>Компьютеры и комплектующие оптом в Москве</a>
            <iframe
                src="https://yandex.kz/map-widget/v1/?from=mapframe&indoorLevel=1&ll=37.594541%2C55.794397&mode=poi&poi%5Bpoint%5D=37.593194%2C55.794253&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D169046270181&pt=37.592833%2C55.794143&source=mapframe&utm_source=mapframe&z=17.22"
                width="1200" height="600" frameBorder="1" allowFullScreen={true} style={{position: 'relative', maxWidth: '100%'}}></iframe>
        </div>
    )
}

export default YandexMap;
