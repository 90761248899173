import * as PropTypes from 'prop-types';
import {Accordion, Form, Row, Col} from 'react-bootstrap';
import ReactSlider from 'react-slider';
import styles from './slider-filter.module.css'

export function SliderFilter(props) {
    console.log(props.values);
    return (
        <Accordion
            className={styles.accordion}
            defaultActiveKey={'price'}
            bsPrefix="accordion ultranout-catalogue-accordion"
            alwaysOpen>
            <Accordion.Item eventKey={'price'}>
                <Accordion.Header>{props.name.toUpperCase()}</Accordion.Header>
                <Accordion.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Control
                                    placeholder={props.minPlaceholder}
                                    value={props.values[0]}
                                    onChange={props.onChange}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    placeholder={props.maxPlaceholder}
                                    defaultValue={props.values[1]}
                                    onChange={props.onChange1}
                                    onAfterChange={props.onAfterChange}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <ReactSlider
                        className={styles.slider}
                        thumbClassName={styles.thumb}
                        trackClassName="track"
                        value={props.values}
                        min={props.defaultValues[0]}
                        max={props.defaultValues[1]}
                        onChange={props.onChange2}
                        onAfterChange={props.onAfterChange1}
                        minDistance={props.minDistance}
                        step={props.step}
                    />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

SliderFilter.propTypes = {
    name: PropTypes.any,
    minDistance: PropTypes.number,
    step: PropTypes.number,
    minPlaceholder: PropTypes.any,
    maxPlaceholder: PropTypes.any,
    values: PropTypes.any,
    onChange: PropTypes.func,
    onChange1: PropTypes.func,
    onAfterChange: PropTypes.func,
    defaultValues: PropTypes.any,
    onChange2: PropTypes.func,
    onAfterChange1: PropTypes.func,
};
