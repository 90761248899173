import { useEffect, useState } from 'react';
import axios from 'axios';

export const useMinMaxWeight = () => {
  const [weight, setWeight] = useState([])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/products/minMaxWeight`).then((response) => {
      console.log(response.data);
      setWeight(response.data);
    });
  }, [])

  return [weight, setWeight]
};
