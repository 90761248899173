//Правила работы магазина
import PageTitle from '../../components/page-title/pageTitle';
import { Accordion } from 'react-bootstrap';
import './storeRules.css';

const StoreRules = () => {
    return (
        <div className="store-rules">
            <PageTitle
                title="Правила работы магазина"
                bottomText="Главная / Правила работы магазина"
            />
            <div className="static-page_text">
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header bsPrefix="accordion-header store-rules_accordion-header">
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    Правила работы демо-зала (шоурум), услуга
                                    «проверка товара перед покупкой»
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="static-page_text-list-item">
                                Просмотр и сравнение интересующих моделей
                                возможен в демонстрационном зале нашего
                                розничного представителя по адресу: г. Москва,
                                улица Сущевский вал, д. 5, стр. 1а ( ТЦ
                                "Компьютерный" )
                            </p>
                            <p className="static-page_text-list-item">
                                В магазине действует система предзаказа: дорогие
                                и новые товары на витрину не выставляются! Товар
                                доставляется со склада в течении дня! Просьба
                                отнестись с пониманием!
                            </p>
                            <p className="static-page_text-list-item">
                                Перед приездом необходимо заранее согласовать
                                просмотр или покупку интересующей модели с
                                менеджером! Иначе магазин вправе отказать в
                                демонстрации и продаже товара!
                            </p>
                            <ol>
                                <li className="static-page_text-list-item">
                                    Согласование интересующих моделей происходит
                                    заранее, за день, перед приходом в магазин.
                                    При обращении «день-в-день» магазин имеет
                                    право отказать в просмотре товара (так как
                                    конкретная модель из наличия может
                                    находиться на удаленном складе/может быть
                                    зарезервирована).
                                </li>
                                <li className="static-page_text-list-item">
                                    Услуга является бесплатной только для
                                    количества моделей не более 3х. По времени
                                    выделяется не более 20 минут на просмотр
                                    одной модели. Установка сторонних программ,
                                    тестирование, разбор техники – только по
                                    дополнительному согласованию
                                </li>
                                <li className="static-page_text-list-item">
                                    Услуга является бесплатной – только для
                                    моделей, находящихся во вскрытой упаковке.
                                    Новые, запечатанные товары – открываются и
                                    демонстрируются платно, стоимость
                                    определяется индивидуально, до
                                    5000руб/штуку. При этом – в случае покупки
                                    данного товара, оплата за просмотр не
                                    взымается.
                                </li>
                                <li className="static-page_text-list-item">
                                    Услуга «проверка товара перед покупкой» –
                                    является бесплатной только в случае покупки
                                    товара (фактической оплаты) или в случае
                                    обнаружения брака товара – в остальных
                                    случаях услуга платная, стоимость до 3000
                                    рублей, определяется индивидуально.
                                </li>
                                <li className="static-page_text-list-item">
                                    Все платные услуги – оплачиваются перед
                                    исполнением, но их стоимость вычитается,
                                    если данный товар покупают
                                </li>
                                <li className="static-page_text-list-item">
                                    Пункты №2 и №3 – могут быть изменены или их
                                    условия расширены (например, кол-во моделей
                                    для просмотра) – только по решению
                                    продающего менеджера и под его
                                    ответственность
                                </li>
                            </ol>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    Правила оформления заказов на товары, не
                                    находящиеся «в наличии»
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ol>
                                <li className="static-page_text-list-item">
                                    Характеристики товара, кол-во, стоимость,
                                    ориентировочные сроки поставки, а также
                                    размер предоплаты – согласовываются и
                                    фиксируются в электронном чате или почте,
                                    заказчику выдаётся документ, в котором
                                    прописываются все детали сделки, выдаётся
                                    кассовый чек на сумму предоплаты. В случае
                                    нестабильности курса валюты, фиксируется
                                    стоимость в “условных единицах», при
                                    внесении предоплаты и последующей оплаты
                                    производится пересчёт по актуальному курсу.
                                    Если курс сильно изменился – покупатель
                                    вправе отказаться от сделки с полным
                                    возвратом внесенной предоплаты
                                </li>
                                <li className="static-page_text-list-item">
                                    Заказчик вправе отказаться от заказа на
                                    любом этапе сделки только в случае нарушения
                                    оговорённых сроков поставки и/или стоимости,
                                    а также в случае несоответствия фактического
                                    товара заявленному изначально в договоре.
                                    Предоплата возвращается в полном объеме, в
                                    установленном законодательством порядке (10
                                    дней), если в договоре не указаны иные
                                    условия
                                </li>
                                <li className="static-page_text-list-item">
                                    После оформления заказа и получения
                                    предоплаты у поставщика возникает
                                    обязательство или привезти согласованный
                                    заказ, или вернуть предоплату. Заказчик
                                    обязуется принять и оплатить товар в срок,
                                    не превышающий 3х рабочих дней после
                                    поступления товара в магазин, если стороны
                                    не согласовали иное
                                </li>
                                <li className="static-page_text-list-item">
                                    Сроки, указанные в договоре – являются
                                    ориентировочными, при нарушении сроков
                                    поставки более чем на три дня, заказчик
                                    вправе отказаться от сделки, предоплата
                                    возвращается в полном объёме. Поставщик не
                                    несёт ответственности за любой ущерб,
                                    причинённый задержкой поставки.
                                </li>
                            </ol>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    Правила сервисного центра (платное сервисное
                                    обслуживание)
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ol>
                                <li className="static-page_text-list-item">
                                    Постгарантийное обслуживание возможно ТОЛЬКО
                                    для клиентов, купивших данный ноутбук в
                                    компании UltraNout или у нашего розничного
                                    представителя. Данное условие может быть
                                    пересмотрено в индивидуальном порядке,
                                    подробности следует уточнять у менеджера
                                </li>
                                <li className="static-page_text-list-item">
                                    Перед назначением диагностики менеджер
                                    вправе запросить серийный номер вашего
                                    устройства для проверки и установления факта
                                    покупки в нашем магазине.
                                </li>
                                <li className="static-page_text-list-item">
                                    Диагностика неисправности проводится
                                    БЕСПЛАТНО и может занять до 10 дней, мы
                                    работаем по законодательству РФ.
                                </li>
                                <li className="static-page_text-list-item">
                                    Если на вашем устройстве установлены пароли,
                                    сообщите нам или заблаговременно удалите их.
                                </li>
                                <li className="static-page_text-list-item">
                                    Наш сервис не несёт ответственности за
                                    данные, находящиеся на компьютере
                                    пользователя. В процессе диагностики может
                                    понадобиться переустановка системы, влекущая
                                    за собой утрату данных. Пожалуйста,
                                    СОХРАНЯЙТЕ ДАННЫЕ самостоятельно или
                                    попросите нас помочь в этом.
                                </li>
                                <li className="static-page_text-list-item">
                                    После диагностики специалист индивидуально
                                    определяет варианты решения проблемы,
                                    стоимость и время ремонта, если предложенные
                                    услуги не подходят по мнению заказчика,
                                    клиент вправе отказаться и забрать свою
                                    технику из ремонта.
                                    <ul style={{ listStyle: "'- '" }}>
                                        <li className="static-page_text-list-item">
                                            Стоимость работ рассчитывается СО
                                            СКИДКОЙ 40%, стоимость комплектующих
                                            берётся по себестоимости, без
                                            наценки
                                        </li>
                                        <li className="static-page_text-list-item">
                                            Время ремонта определяется
                                            индивидуально. Наш сервис работает
                                            профессионально, аккуратно и
                                            вдумчиво, по этой причине у нас нет
                                            услуги «срочного ремонта»
                                        </li>
                                        <li className="static-page_text-list-item">
                                            Пожалуйста, будьте готовы что
                                            процесс займёт время
                                        </li>
                                    </ul>
                                </li>
                                <li className="static-page_text-list-item">
                                    Компания UltraNout берёт оплату только в
                                    случае успешного ремонта, по заранее
                                    согласованному перечню работ. В случае
                                    неудачного ремонта, расходы по произведённым
                                    работам оплачивает компания UltraNout.
                                    Финансовые издержки, связанные с возможными
                                    задержками в сроках ремонта, несёт заказчик.
                                </li>
                                <li className="static-page_text-list-item">
                                    Отправка/доставка до нашего сервисного
                                    центра и обратно осуществляется ЗА СЧЁТ
                                    ОТПРАВИТЕЛЯ.
                                </li>
                            </ol>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    Правила сервисного центра (бесплатное
                                    гарантийное обслуживание)
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ol>
                                <li className="static-page_text-list-item">
                                    Бесплатное гарантийное обслуживание возможно
                                    ТОЛЬКО для клиентов, купивших данный ноутбук
                                    в компании «UltraNout» или у нашего
                                    розничного представителя, при обращении по
                                    гарантии Вам потребуется предъявить ЧЕК и
                                    ГАРАНТИЙНЫЙ ТАЛОН, сохраните их, пожалуйста!
                                </li>
                                <li className="static-page_text-list-item">
                                    Перед назначением диагностики менеджер
                                    вправе запросить серийный номер вашего
                                    устройства для проверки и установления факта
                                    покупки в нашем магазине.
                                </li>
                                <li className="static-page_text-list-item">
                                    Диагностика неисправности проводится
                                    БЕСПЛАТНО и может занять до 10 дней, мы
                                    работаем по законодательству РФ.
                                </li>
                                <li className="static-page_text-list-item">
                                    Если на вашем устройстве установлены пароли,
                                    сообщите нам или заблаговременно удалите их.
                                </li>
                                <li className="static-page_text-list-item">
                                    Наш сервис не несёт ответственности за
                                    данные, находящиеся на компьютере
                                    пользователя. В процессе диагностики может
                                    понадобиться переустановка системы, влекущая
                                    за собой утрату данных. Пожалуйста,
                                    СОХРАНЯЙТЕ ДАННЫЕ самостоятельно или
                                    попросите нас помочь в этом.
                                </li>
                                <li className="static-page_text-list-item">
                                    После диагностики специалист индивидуально
                                    определяет варианты решения проблемы,
                                    стоимость и время ремонта, если предложенные
                                    услуги не подходят по мнению заказчика,
                                    клиент вправе отказаться и забрать свою
                                    технику из ремонта.
                                    <ul style={{ listStyle: "'- '" }}>
                                        <li className="static-page_text-list-item">
                                            Стоимость работ рассчитывается СО
                                            СКИДКОЙ 40%, стоимость комплектующих
                                            берётся по себестоимости, без
                                            наценки
                                        </li>
                                        <li className="static-page_text-list-item">
                                            Время ремонта определяется
                                            индивидуально. Наш сервис работает
                                            профессионально, аккуратно и
                                            вдумчиво, по этой причине у нас нет
                                            услуги «срочного ремонта»
                                        </li>
                                        <li className="static-page_text-list-item">
                                            Пожалуйста, будьте готовы что
                                            процесс займёт время
                                        </li>
                                    </ul>
                                </li>
                                <li className="static-page_text-list-item">
                                    Случаи, не являющиеся гарантийными:
                                    <ul style={{ listStyle: "'- '" }}>
                                        <li className="static-page_text-list-item">
                                            неисправность ноутбука по причине
                                            механических повреждений;
                                        </li>
                                        <li className="static-page_text-list-item">
                                            неисправность ноутбука, вызванная
                                            воздействием жидкости или газов
                                            (пролили на ноутбук жидкость или
                                            обнаружился конденсат внутри
                                            ноутбука);
                                        </li>
                                        <li className="static-page_text-list-item">
                                            некорректные воздействия на софт
                                            устройства (попытки самостоятельно
                                            «перепрошить» БИОС);
                                        </li>
                                        <li className="static-page_text-list-item">
                                            перебои электропитания, короткое
                                            замыкание;
                                        </li>
                                        <li className="static-page_text-list-item">
                                            а также все случаи, определённые
                                            производителем как не подлежащие
                                            бесплатной гарантии.
                                        </li>
                                    </ul>
                                </li>
                                <li className="static-page_text-list-item">
                                    Срок бесплатного гарантийного обслуживания
                                    указан в вашем гарантийном талоне, сверяйте
                                    даты перед обращением! Для наших клиентов
                                    доступны также льготные условия по ремонту
                                    компьютерной техники в постгарантийный
                                    период.
                                </li>
                                <li className="static-page_text-list-item">
                                    Отправка/доставка до нашего сервисного
                                    центра и обратно осуществляется ЗА СЧЁТ
                                    ОТПРАВИТЕЛЯ.
                                </li>
                            </ol>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    );
};

export default StoreRules;
