import "../../styles/header.css"
import {Button} from "react-bootstrap";
import SocialIcons from '../social-icons/socialIcons';
import { useState } from 'react';
import ContactFormModal from '../contact-form-modal';

const HeaderSocial = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="header-social">
            {
              showModal && (
                <ContactFormModal show={showModal} setShowModal={setShowModal}/>
              )
            }
            <div className="header-social_links">
                <SocialIcons size='2xl'/>
            </div>
            <div className="header-social_contacts">
                <div className="header-social_contacts-element" aria-label='ЧАСЫ РАБОТЫ МАГАЗИНА С 11:00 ДО 20:30' style={{color: 'rgb(62 62 62)', letterSpacing: '0.02rem'}}>
                    ЧАСЫ РАБОТЫ МАГАЗИНА С 11:00 ДО 20:30
                </div>
                <a
                    href="tel:+7(499)110-77-67"
                    className="header-social_contacts-element"
                    style={{color: 'rgb(62 62 62)', letterSpacing: '0.02rem'}}>
                    +7(499)110-77-67
                </a>
                <div id={"yandexContainer"} style={{display: sessionStorage.getItem('cart_id') ? 'none' : 'initial', width: '170px', height: 'auto'}}>

                </div>
                <div>
                    <Button
                        variant="secondary"
                        className="btn-sm header-social_contacts-element-button"
                        onClick={() => setShowModal(true)}
                    >
                        Связаться с нами
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default HeaderSocial;
