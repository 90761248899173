// Компонент для отображения одной строки таблицы с продуктом в корзине
import { useState } from 'react';
import { ModalOnDeleteFromCart } from '../modal-on-delete-from-cart/ModalOnDeleteFromCart';
import CounterInput from 'react-counter-input';
import { Button } from 'react-bootstrap';
import styles from './cart-row.module.css'

export function CartRow({ product, onRemove, onQuantityChange }) {
    const [showModal, setShowModal] = useState(false);

    function handleDeleteProduct() {
        setShowModal(true);
    }

    return (
        <tr className={styles.row}>
            {showModal && (
                <ModalOnDeleteFromCart
                    show={showModal}
                    setShowModal={setShowModal}
                    deleteFromCart={() => onRemove(product.id)}
                />
            )}
            <td>
                <img
                    className={styles.image}
                    src={product.images[0].src}
                    alt={product.name}
                />
            </td>
            <td>{product.name}</td>
            <td>{product.salePrice} руб.</td>
            <td>
                <CounterInput
                    min={1}
                    count={product.quantity}
                    wrapperStyle={{
                        border: '1px solid rgb(0 0 0 / 11%)',
                    }}
                    btnStyle={{
                        border: '1px solid rgb(0 0 0 / 11%)',
                    }}
                    onCountChange={(count) => onQuantityChange(count, product)}
                />
            </td>
            <td>{product.salePrice * product.quantity} руб.</td>
            <td>
                <Button
                    bsPrefix="btn button_main_color"
                    onClick={handleDeleteProduct}>
                    Удалить
                </Button>
            </td>
        </tr>
    );
}
