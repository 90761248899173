import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const ModalOnAddToCart = ({ show, setShowModal }) => {
    return (
        <Modal show={show} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Товар успешно добавлен в корзину</Modal.Title>
            </Modal.Header>
            <Modal.Footer bsPrefix="modal-footer">
                <Button
                    bsPrefix="btn button_wrapper"
                    onClick={() => setShowModal(false)}>
                    <u>Продолжить покупки</u>
                </Button>
                <Link to="/cart">
                    <Button
                        bsPrefix="btn button_main_color-rounded"
                        onClick={() => setShowModal(false)}>
                        Просмотр корзины
                    </Button>
                </Link>
            </Modal.Footer>
        </Modal>
    );
};
