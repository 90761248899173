import React, { useState } from 'react';
import ProductCard from '../../components/product-card';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import handleCartClick from '../../helpers/handleCartClick';
import { handleWishlistClick } from '../../helpers/handleWishlistClick';
import './Wishlist.css';
import { handleCompareClick } from '../../helpers/handleCompareClick';

function Wishlist() {
    // Создаем состояние для хранения списка желаемого
    const [wishlist, setWishlist] = useState(
        JSON.parse(localStorage.getItem('wishlist')),
    );
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [compare, setCompare] = useState(
        JSON.parse(localStorage.getItem('compare')),
    );

    console.log(wishlist);
    // Функция для подсчета общей стоимости товаров в списке
    const getTotalPrice = () => {
        // Суммируем цены всех товаров
        console.log('total');
        return wishlist.reduce(
            (total, product) => total + Number(product.salePrice),
            0,
        );
    };

    return (
        <div className="wishlist-wrapper">
            <h1>Список избранного</h1>
            <div
                className="wishlist"
                >
                <div
                    className="wishlist_products"
                    >
                    {wishlist.map((product, index) => (
                        <div key={index} className="product-card_wrapper">
                            <ProductCard
                                product={product}
                                verticalMode={true}
                                onWishlistClick={() =>
                                    handleWishlistClick(
                                        product,
                                        wishlist,
                                        setWishlist,
                                    )
                                }
                                onCartClick={() =>
                                    handleCartClick(product, cart, setCart)
                                }
                                isInCart={
                                    !cart.some(
                                        (wishlistProduct) =>
                                            wishlistProduct.id === product.id,
                                    )
                                }
                                isInCompare={
                                    !compare.some(
                                        (compareProduct) =>
                                            compareProduct.id === product.id,
                                    )
                                }
                                onCompareClick={() =>
                                    handleCompareClick(
                                        product,
                                        compare,
                                        setCompare,
                                    )
                                }
                            />
                        </div>
                    ))}
                </div>
                {/*<div*/}
                {/*    className="wishlist__total"*/}
                {/*    style={{*/}
                {/*        display: 'flex',*/}
                {/*        flexDirection: 'column',*/}
                {/*    }}>*/}
                {/*    <h2>Общая стоимость:</h2>*/}
                {/*    <OverlayTrigger*/}
                {/*        overlay={*/}
                {/*            <Tooltip>*/}
                {/*                Цена актуальна при оплате наличными*/}
                {/*            </Tooltip>*/}
                {/*        }*/}
                {/*        placement={'top'}>*/}
                {/*        <h3*/}
                {/*            style={{*/}
                {/*                color: 'rgba(245, 102, 34, 1)',*/}
                {/*            }}>*/}
                {/*            {getTotalPrice()} руб.*/}
                {/*        </h3>*/}
                {/*    </OverlayTrigger>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Wishlist;
