import '../../styles/footer.css'
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <div className="footer">
            <div className="footer-rights" style={{textAlign: 'center'}}>
                САЙТ РАЗРАБОТАН КОМПАНИЕЙ <Link to="/"> ULTRANOUT.RU </Link>{' '}
                2014-{currentYear}. НАША ЦЕЛЬ - ДОВОЛЬНЫЙ ПОКУПАТЕЛЬ
            </div>
            <div className="footer-payment-methods">
                <img
                    style={{ transform: 'scale(0.80)', width: '100%' }}
                    src={
                        `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2023/02/horizontallogos.png`
                    }
                    alt=""
                />
            </div>
        </div>
    );
}

export default Footer;
