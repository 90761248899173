import {
    Badge,
    Button,
    FormControl,
    InputGroup,
    ListGroup,
    Offcanvas,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faCartShopping,
    faChevronDown, faEnvelope,
    faMagnifyingGlass,
    faPhoneFlip,
    faShuffle,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import styles from './mobile-header.module.css';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import ContactFormModal from '../../components/contact-form-modal';

export const MobileHeader = ({}) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [badges, setBadges] = useState({
        wishlist: JSON.parse(localStorage.getItem('wishlist')).length,
        cart: JSON.parse(localStorage.getItem('cart')).length,
        compare: JSON.parse(localStorage.getItem('compare')).length,
    });
    const [showNavbar, setShowNavbar] = useState(false);
    const handleShowNavbar = () => setShowNavbar(true);
    const handleCloseNavbar = () => setShowNavbar(false);

    const [showCatalogueCategories, setShowCatalogueCategories] =
        useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const handleShowCatalogueCategories = () =>
        setShowCatalogueCategories(!showCatalogueCategories);

    const displayModal = () => {
        setShowModal(true)
    }

    useEffect(() => {
        const onStorage = () => {
            setBadges({
                wishlist: JSON.parse(localStorage.getItem('wishlist')).length,
                cart: JSON.parse(localStorage.getItem('cart')).length,
                compare: JSON.parse(localStorage.getItem('compare')).length,
            });
        };

        window.addEventListener('storage', onStorage);

        return () => {
            window.removeEventListener('storage', onStorage);
        };
    }, []);

    return (
        <div className={styles.mobileHeader}>
            {
                showModal && (
                    <ContactFormModal show={showModal} setShowModal={setShowModal}/>
                )
            }
            <div>
                <FontAwesomeIcon
                    icon={faBars}
                    size="xl"
                    onClick={handleShowNavbar}
                />
                <Offcanvas
                    show={showNavbar}
                    onHide={handleCloseNavbar}
                    className={styles.navbar}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Меню</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body className={styles.offcanvas}>
                        <ListGroup>
                            <InputGroup
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <FormControl
                                    id="search"
                                    placeholder="Поиск товара"
                                    value={searchTerm}
                                    onChange={(event) =>
                                        setSearchTerm(event.target.value)
                                    }
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            navigate(
                                                `/catalogue?searchString=${searchTerm}`,
                                            );
                                            setShowNavbar(false);
                                        }
                                    }}></FormControl>
                                <Button
                                    bsPrefix="btn button_main_color"
                                    onClick={() => {
                                        navigate(
                                            `/catalogue?searchString=${searchTerm}`,
                                        );
                                        setShowNavbar(false);
                                    }}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </Button>
                            </InputGroup>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <a
                                    style={{
                                        textDecoration: 'none',
                                        color: 'rgba(245, 102, 34, 1)',
                                    }}
                                    href="tel:+7(499)110-77-67">
                                    +7(499)110-77-67
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <a
                                    href="mailto:ultranout@gmail.com"
                                    className="woodmart-nav-link"
                                    style={{
                                        textDecoration: 'none',
                                        color: 'rgba(245, 102, 34, 1)',
                                    }}>
                                    <span className="nav-link-text">
                                        Email:
                                    </span>
                                    <span className="menu-label menu-label-primary">
                                        ultranout@gmail.com
                                    </span>
                                </a>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/about"
                                    className="header-navbar_item">
                                    Почему мы
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}>
                                <Link
                                    to="/catalogue"
                                    className="header-navbar_item"
                                    onClick={handleCloseNavbar}>
                                    Каталог
                                </Link>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    onClick={handleShowCatalogueCategories}
                                />
                            </ListGroup.Item>
                            <ListGroup
                                style={{
                                    display: showCatalogueCategories
                                        ? 'block'
                                        : 'none',
                                }}>
                                <Link
                                    to="/catalogue/igrovoj-noutbuk"
                                    className="header-navbar_item"
                                    onClick={handleCloseNavbar}>
                                    <ListGroup.Item>
                                        Игровой ноутбук
                                    </ListGroup.Item>
                                </Link>
                                <Link
                                    to="/catalogue/rabochaja-stancija"
                                    className="header-navbar_item"
                                    onClick={handleCloseNavbar}>
                                    <ListGroup.Item>
                                        Рабочая станция
                                    </ListGroup.Item>
                                </Link>
                                <Link
                                    to="/catalogue/transformer"
                                    className="header-navbar_item"
                                    onClick={handleCloseNavbar}>
                                    <ListGroup.Item>Трансформер</ListGroup.Item>
                                </Link>
                                <Link
                                    to="/catalogue/ultrabuk"
                                    className="header-navbar_item"
                                    onClick={handleCloseNavbar}>
                                    <ListGroup.Item>Ультрабук</ListGroup.Item>
                                </Link>
                            </ListGroup>
                            {/*<ListGroup.Item><Link to="/ultraguide" className="header-navbar_item">УльтраГид</Link></ListGroup.Item>*/}
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/storeRules"
                                    className="header-navbar_item">
                                    Правила работы магазина
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/paymentOptions"
                                    className="header-navbar_item">
                                    Оплата
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/warranty"
                                    className="header-navbar_item">
                                    Гарантия
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/deliveryMethods"
                                    className="header-navbar_item">
                                    Доставка
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/reviews"
                                    className="header-navbar_item">
                                    Отзывы
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/contactUs"
                                    className="header-navbar_item">
                                    Контакты
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/wishlist"
                                    className="header-navbar_item">
                                    <FontAwesomeIcon
                                        icon={faHeart}
                                        style={{ paddingRight: '0.375rem' }}
                                    />
                                    Избранное
                                </Link>
                            </ListGroup.Item>
                            <ListGroup.Item onClick={handleCloseNavbar}>
                                <Link
                                    to="/compare"
                                    className="header-navbar_item">
                                    <FontAwesomeIcon
                                        icon={faShuffle}
                                        style={{ paddingRight: '0.375rem' }}
                                    />
                                    Сравнить
                                </Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </Offcanvas.Body>
                </Offcanvas>
                <Link to="/" className="site-logo_mobile">
                    <img
                        style={{ display: 'inline-block' }}
                        className="site-logo_mobile"
                        src={`${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2021/09/new-logo.jpeg`}
                        alt=""
                    />
                </Link>
            </div>
            <div className={styles.buttons}>
                <a
                    href="tel:+7(499)110-77-67"
                    className={styles.icon}>
                    <FontAwesomeIcon icon={faPhoneFlip} />
                </a>
                <button className={styles.icon} onClick={displayModal}>
                    <FontAwesomeIcon icon={faEnvelope} />
                </button>
                <a
                    className={styles.icon}
                    href="https://market.yandex.ru/business--ultra-nout/13529706?_redirectCount=1"
                    target="_blank"
                    rel="noreferrer">
                    <span className={styles.yandexMarket}></span>
                </a>
                <Link
                    className="header-social_links-icon"
                    to="/cart">
                    <Badge pill bg="ultranout_color_mobile">
                        {badges.cart}
                    </Badge>
                    <FontAwesomeIcon icon={faCartShopping} size="xl" />
                </Link>
            </div>
        </div>
    );
};
