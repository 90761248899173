import { Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../helpers/api';
import axios from 'axios';

const CheckoutSuccess = () => {
    const { orderId } = useParams();
    const [orderBody, setOrderBody] = useState({});

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/order?id=${orderId}`).then((response) => {
              setOrderBody(response.data);
              console.log(response.data);
          }
        );
    }, []);

    // Функция для подсчета общей суммы корзины
    function getTotal() {
        return orderBody.products?.reduce(
            (total, product) => total + product.order_products.priceOnCreation * product.order_products.quantity,
            0,
        );
    }

    return (
        <div
            className="checkout-success_wrapper"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
            <div
                className="checkout-success"
                style={{
                    width: '50%',
                    margin: '40px',
                }}>
                <div
                    className="order-success_thanks"
                    style={{
                        border: '2px dashed rgb(122 156 89)',
                        padding: '3%',
                        color: 'rgb(122 156 89)',
                        fontSize: '22px',
                        fontWeight: '600',
                        textAlign: 'center',
                    }}>
                    <h3>Ваш заказ принят. Благодарим вас</h3>
                </div>
                <Table style={{ margin: '10px 0 10px 0' }}>
                    <thead>
                        <tr>
                            <th>Номер заказа</th>
                            <th>Итого</th>
                            <th>Дата</th>
                            <th>Метод оплаты</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{orderBody.id}</td>
                            <td>{orderBody.total}</td>
                            <td>{orderBody.createdAt?.split('T')[0]}</td>
                            <td>
                                {orderBody.payment_method === 'cheque'
                                    ? 'Оплата наличными при получении'
                                    : 'Оплата картой онлайн'}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <p style={{ textAlign: 'center' }}>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: '#0000ff',
                        }}>
                        <em>
                            <span style={{ fontSize: '14pt' }}>
                                Вся информация по заказу отправлена вам на почту. Просьба убедится в получении письма и проверить данные. При обнаружении расхождений просьба связаться с магазином (+7-499-110-7767) или вашим персональным менеджером.
                            </span>
                        </em>
                    </span>
                </p>
                {/*<p>*/}
                {/*    Ввиду сложившейся ситуации актуальность цен и резерв просьба*/}
                {/*    подтверждать у менеджера с помощью звонка или через*/}
                {/*    электронный чат*/}
                {/*</p>*/}
                {/*<h4 style={{ textTransform: 'uppercase' }}>*/}
                {/*    Информация о заказе*/}
                {/*</h4>*/}
                {/*<Table style={{ margin: '10px 0 10px 0' }}>*/}
                {/*    <thead>*/}
                {/*        <tr>*/}
                {/*            <th*/}
                {/*                style={{*/}
                {/*                    textTransform: 'uppercase',*/}
                {/*                }}>*/}
                {/*                Товар*/}
                {/*            </th>*/}
                {/*            <th*/}
                {/*                style={{*/}
                {/*                    textTransform: 'uppercase',*/}
                {/*                }}>*/}
                {/*                Итого*/}
                {/*            </th>*/}
                {/*        </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*        {!!orderBody &&*/}
                {/*            orderBody?.line_items?.map((product) => (*/}
                {/*                <tr>*/}
                {/*                    <td>*/}
                {/*                        <strong>{product.name}</strong> x{' '}*/}
                {/*                        {product.quantity}*/}
                {/*                    </td>*/}
                {/*                    <td>*/}
                {/*                        {Number(product.price)?.toLocaleString(*/}
                {/*                            'en-US',*/}
                {/*                        )}{' '}*/}
                {/*                        &#8381;*/}
                {/*                    </td>*/}
                {/*                </tr>*/}
                {/*            ))}*/}
                {/*        <tr>*/}
                {/*            <td>Подытог</td>*/}
                {/*            <td style={{ color: 'rgb(245,102,34)' }}>*/}
                {/*                {getTotal()?.toLocaleString('en-US')} &#8381;*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <td>Доставка</td>*/}
                {/*            <td>Бесплатная доставка</td>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <td>Метод оплаты</td>*/}
                {/*            <td>*/}
                {/*                {orderBody.payment_method === 'cheque'*/}
                {/*                    ? 'Оплата наличными при получении'*/}
                {/*                    : 'Оплата картой онлайн'}*/}
                {/*            </td>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <th style={{ textTransform: 'uppercase' }}>*/}
                {/*                Итого*/}
                {/*            </th>*/}
                {/*            <th style={{ color: 'rgb(245,102,34)' }}>*/}
                {/*                {getTotal()?.toLocaleString('en-US')} &#8381;*/}
                {/*            </th>*/}
                {/*        </tr>*/}
                {/*    </tbody>*/}
                {/*</Table>*/}
                {/*<div className="billing-address">*/}
                {/*    <h4 style={{ textTransform: 'uppercase' }}>*/}
                {/*        Платежный адрес*/}
                {/*    </h4>*/}
                {/*    <address style={{ fontStyle: 'italic' }}>*/}
                {/*        {orderBody.shippingInfo?.name}{' '}*/}
                {/*        {orderBody.shippingInfo?.last_name}*/}
                {/*        <br />*/}
                {/*        {orderBody.shippingInfo?.address}*/}
                {/*        <br/>*/}
                {/*        {orderBody.shippingInfo?.city}*/}
                {/*        <br/>*/}
                {/*        {orderBody.shippingInfo?.phone_number}*/}
                {/*        <br/>*/}
                {/*        {orderBody.shippingInfo?.email}*/}
                {/*    </address>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default CheckoutSuccess;
