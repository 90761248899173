import {
    Accordion,
    Button,
    Card,
    Form,
    Table,
    useAccordionButton,
} from 'react-bootstrap';
import { useFormikContext } from 'formik';
import styles from './payment-form.module.css';
import { clsx } from 'clsx';

const CustomAccordionToggle = ({
    eventKey,
    callback,
    method,
    checked,
    onChange,
}) => {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <Form.Check
            eventKey="0"
            name="payment-method-group"
            type="radio"
            label={method.title}
            value={method.id}
            checked={checked}
            onClick={decoratedOnClick}
            onChange={onChange}
        />
    );
};

const PaymentForm = ({
    products,
    paymentMethods,
    selectedMethod,
    setSelectedMethod,
}) => {
    const { values, submitForm } = useFormikContext();

    const onPaymentMethodChange = ({ target: { value } }) => {
        setSelectedMethod(value);
    };
    // Функция для подсчета подитога
    const getSubtotal = () => {
        return products.reduce(
            (total, product) => total + product.salePrice * product.quantity,
            0,
        );
    };

    const getOnlinePaymentFee = () => {
        const salePrice = products.reduce(
          (total, product) => total + product.salePrice * product.quantity,
          0,
        );
        const regularPrice = products.reduce(
          (total, product) => total + product.regularPrice * product.quantity,
          0,
        );
        return regularPrice - salePrice;
    };

    //Функция для подсчета полной итоговой суммы. Прибавляем процент если выбран безнал
    const getFullTotal = () => {
        const subtotal = getSubtotal();
        if (selectedMethod !== 'cheque') {
            return products.reduce(
              (total, product) => total + product.regularPrice * product.quantity,
              0,
            )
        }
        return subtotal;
    };

    return (
        <div
            className={styles.wrapper}
            >
            <h2>Ваш заказ</h2>
            <div className={styles.tableWrapper}>
                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th className="product-name">Товар</th>
                            <th className="product-total">Сумма</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product) => (
                            <tr className="cart_item">
                                <td className="product-name">
                                    {product.name}
                                    <strong className="product-quantity">
                                        {' '}
                                        x {product.quantity}
                                    </strong>
                                </td>
                                <td className="product-total">
                                    <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                            {product.salePrice * product.quantity}
                                            <span className="woocommerce-Price-currencySymbol">
                                                ₽
                                            </span>
                                        </bdi>
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr className="cart-subtotal">
                            <th>Общая стоимость заказа</th>
                            <td>
                                <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                        {getSubtotal()}
                                        <span className="woocommerce-Price-currencySymbol">
                                            ₽
                                        </span>
                                    </bdi>
                                </span>
                            </td>
                        </tr>

                        <tr className="woocommerce-shipping-totals shipping">
                            <th>Доставка</th>
                            <td data-title="Доставка">Бесплатная доставка</td>
                        </tr>

                        <tr
                            className="order-payment-fee"
                            style={{
                                visibility:
                                    selectedMethod === 'cheque'
                                        ? 'hidden'
                                        : 'visible',
                                opacity:
                                    selectedMethod === 'cheque' ? '0' : '1',
                                transition:
                                    'visibility 0.5s, opacity 0.5s linear',
                            }}>
                            <th>+% при оплате по безналичному расчету</th>
                            <td>
                                <strong>
                                    <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                            {getOnlinePaymentFee()}
                                            <span className="woocommerce-Price-currencySymbol">
                                                ₽
                                            </span>
                                        </bdi>
                                    </span>
                                </strong>
                            </td>
                        </tr>

                        <tr className="order-total">
                            <th>Итого</th>
                            <td>
                                <strong>
                                    <span className="woocommerce-Price-amount amount">
                                        <bdi>
                                            {getFullTotal()}
                                            <span className="woocommerce-Price-currencySymbol">
                                                ₽
                                            </span>
                                        </bdi>
                                    </span>
                                </strong>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </div>
            <Form
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'flex-start',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    margin: '20px',
                }}>
                <Accordion style={{ width: '90%' }} defaultActiveKey={0}>
                    {paymentMethods.map((method, index) => (
                        <Card className={styles.noBorder}>
                            <Card.Header className={styles.noBorder}>
                                <CustomAccordionToggle
                                    eventKey={index}
                                    name="payment-method-group"
                                    type="radio"
                                    method={method}
                                    checked={method.id === selectedMethod}
                                    onChange={onPaymentMethodChange}
                                />
                            </Card.Header>
                            <Accordion.Collapse eventKey={index}>
                                <Card.Body>{method.description}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </Form>
            <Button
              className={clsx('button_main_color', styles.confirmButton)}
              type="submit"
              onClick={submitForm}
              id="submit">
                Подтвердить заказ
            </Button>
        </div>
    );
};

export default PaymentForm;
