import {Link} from "react-router-dom";

const Navbar = () => {
    return(
        <div className="header-navbar">
            <Link to="/about" className="header-navbar_item">Почему мы</Link>
            <div className='dropdown header-navbar_item '>
                <Link to="/catalogue" className="header-navbar_item dropbtn">
                    Каталог
                    <div className="dropdown-content">
                        <Link to='/catalogue/igrovoj-noutbuk'>Игровой ноутбук</Link>
                        <Link to='/catalogue/rabochaja-stancija'>Рабочая станция</Link>
                        <Link to='/catalogue/transformer'>Трансформер</Link>
                        <Link to='/catalogue/ultrabuk' href="#">Ультрабук</Link>
                    </div>
                </Link>
            </div>
            {/*<Link to="/ultraguide" className="header-navbar_item">УльтраГид</Link>*/}
            <Link to="/storeRules" className="header-navbar_item">Правила работы магазина</Link>
            <Link to="/options" className="header-navbar_item">Дополнительные услуги</Link>
            <Link to="/paymentOptions" className="header-navbar_item">Оплата</Link>
            <Link to="/warranty" className="header-navbar_item">Гарантия</Link>
            <Link to="/deliveryMethods" className="header-navbar_item">Доставка</Link>
            <Link to="/reviews" className="header-navbar_item">Отзывы</Link>
            <Link to="/contactUs" className="header-navbar_item">Контакты</Link>
        </div>
    )
}

export default Navbar;
