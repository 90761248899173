export const handleCompareClick = (product, compare, setCompare) => {
    // Проверяем, есть ли продукт уже в списке
    const index = compare.indexOf(
        compare.find((item) => item.id === product.id),
    );
    //Метод indexOf возвращает -1 если не находит элемент
    if (index === -1) {
        // Добавляем продукт в конец массива
        const newCompare = [...compare, { ...product }];
        // Обновляем состояние
        setCompare(newCompare);
        // Обновляем localStorage
        localStorage.setItem('compare', JSON.stringify(newCompare));
        window.dispatchEvent(new Event('storage'));
    } else {
        //Создаем новый массив без этого элемента
        const newCompare = [
            ...compare.slice(0, index),
            ...compare.slice(index + 1, compare.length),
        ];
        // Обновляем состояние
        setCompare(newCompare);
        // Обновляем localStorage
        localStorage.setItem('compare', JSON.stringify(newCompare));
        window.dispatchEvent(new Event('storage'));
    }
};
