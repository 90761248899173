import "./page-title.css"
import {Link} from "react-router-dom";

const PageTitle = ({title, bottomText}) => {
    return (
        <div className="page_title">
            <h1 className="page_title-title">{title}</h1>
            <p className="page_title-bottomText"><Link className="page_title-bottomText" to="/">Главная</Link> / {title}</p>
        </div>
    )
}

export default PageTitle
