import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ClearCartAfterSuccessfulOnlinePayment = () => {
    localStorage.setItem('cart', JSON.stringify([]));
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    })
}

export default ClearCartAfterSuccessfulOnlinePayment