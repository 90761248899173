//Оплата
import PageTitle from '../../components/page-title/pageTitle';
import { Accordion, Button } from 'react-bootstrap';
import '../store-rules/storeRules.css';

const PaymentOptions = () => {
    return (
        <div className="payment-methods">
            <PageTitle title="Оплата" bottomText="Главная / Оплата" />
            <div className="static-page_text">
                <Accordion defaultActiveKey={['0']} alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>Оплата за наличный расчет:</strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            Оплата за наличный расчёт возможна в магазине нашего
                            розничного представителя или при доставке курьером
                            (подробнее о доставке в соответствующем разделе)
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>Оплата по безналичному расчету:</strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul style={{ listStyle: "'- '" }}>
                                <li className="static-page_text-list-item">
                                    Online оплата при помощи карт (VISA,
                                    MasterCard);
                                </li>
                                <li className="static-page_text-list-item">
                                    Оплата банковскими картами осуществляется
                                    через АО «АЛЬФА-БАНК».
                                </li>
                                <img
                                    className="static-page_text-list-item"
                                    src={`${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/alfabank-300x96.png`}
                                    alt="Логотип Альфа-банка"
                                />
                                <li className="static-page_text-list-item">
                                    К оплате принимаются карты VISA, MasterCard,
                                    МИР.
                                </li>
                                <img
                                    className="static-page_text-list-item"
                                    src={
                                        `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2022/02/visa-mc-mir-300x52.png`
                                    }
                                    alt="Логотипы платежных систем"
                                />
                                <li className="static-page_text-list-item">
                                    Услуга оплаты через интернет осуществляется
                                    в соответствии с Правилами международных
                                    платежных систем Visa, MasterCard и
                                    Платежной системы МИР на принципах
                                    соблюдения конфиденциальности и безопасности
                                    совершения платежа, для чего используются
                                    самые современные методы проверки,
                                    шифрования и передачи данных по закрытым
                                    каналам связи. Ввод данных банковской карты
                                    осуществляется на защищенной платежной
                                    странице АО «АЛЬФА-БАНК».
                                </li>
                                <li className="static-page_text-list-item">
                                    На странице для ввода данных банковской
                                    карты потребуется ввести данные банковской
                                    карты: номер карты, имя владельца карты,
                                    срок действия карты, трёхзначный код
                                    безопасности (CVV2 для VISA, CVC2 для
                                    MasterCard, Код Дополнительной Идентификации
                                    для МИР). Все необходимые данные пропечатаны
                                    на самой карте. Трёхзначный код безопасности
                                    — это три цифры, находящиеся на обратной
                                    стороне карты.
                                </li>
                                <li className="static-page_text-list-item">
                                    На странице для ввода данных банковской
                                    карты потребуется ввести данные банковской
                                    карты: номер карты, имя владельца карты,
                                    срок действия карты, трёхзначный код
                                    безопасности (CVV2 для VISA, CVC2 для
                                    MasterCard, Код Дополнительной Идентификации
                                    для МИР). Все необходимые данные пропечатаны
                                    на самой карте. Трёхзначный код безопасности
                                    — это три цифры, находящиеся на обратной
                                    стороне карты.
                                </li>
                                <li className="static-page_text-list-item">
                                    Далее вы будете перенаправлены на страницу
                                    Вашего банка для ввода кода безопасности,
                                    который придет к Вам в СМС. Если код
                                    безопасности к Вам не пришел, то следует
                                    обратиться в банк выдавший Вам карту.
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    В случае отказа в совершении платежа:
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul style={{ listStyle: "'- '" }}>
                                <li className="static-page_text-list-item">
                                    банковская карта не предназначена для
                                    совершения платежей через интернет, о чем
                                    можно узнать, обратившись в Ваш Банк;
                                </li>
                                <li className="static-page_text-list-item">
                                    недостаточно средств для оплаты на
                                    банковской карте. Подробнее о наличии
                                    средств на банковской карте Вы можете
                                    узнать, обратившись в банк, выпустивший
                                    банковскую карту;
                                </li>
                                <li className="static-page_text-list-item">
                                    данные банковской карты введены неверно;
                                </li>
                                <li className="static-page_text-list-item">
                                    истек срок действия банковской карты. Срок
                                    действия карты, как правило, указан на
                                    лицевой стороне карты (это месяц и год, до
                                    которого действительна карта). Подробнее о
                                    сроке действия карты Вы можете узнать,
                                    обратившись в банк, выпустивший банковскую
                                    карту;
                                </li>
                            </ul>
                            <h4 className="static-page_text-list-item">
                                Предоставляемая вами персональная информация
                                (имя, адрес, телефон, e-mail, номер банковской
                                карты) является конфиденциальной и не подлежит
                                разглашению. Данные вашей кредитной карты
                                передаются только в зашифрованном виде и не
                                сохраняются на нашем Web-сервере.
                            </h4>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>Правила возврата товара:</strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <ul style={{ listStyle: "'- '" }}>
                                <li className="static-page_text-list-item">
                                    При оплате картами возврат наличными
                                    денежными средствами не допускается. Порядок
                                    возврата регулируется правилами
                                    международных платежных систем.
                                </li>
                                <li className="static-page_text-list-item">
                                    Процедура возврата товара регламентируется
                                    статьей 26.1 федерального закона «О защите
                                    прав потребителей».
                                </li>
                                <li className="static-page_text-list-item">
                                    Потребитель вправе отказаться от товара в
                                    любое время до его передачи, а после
                                    передачи товара – в течение семи дней;
                                </li>
                                <li className="static-page_text-list-item">
                                    Возврат товара надлежащего качества возможен
                                    в случае, если сохранены его товарный вид,
                                    потребительские свойства, а также документ,
                                    подтверждающий факт и условия покупки
                                    указанного товара;
                                </li>
                                <li className="static-page_text-list-item">
                                    Потребитель не вправе отказаться от товара
                                    надлежащего качества, имеющего
                                    индивидуально-определенные свойства, если
                                    указанный товар может быть использован
                                    исключительно приобретающим его человеком;
                                </li>
                                <li className="static-page_text-list-item">
                                    При отказе потребителя от товара продавец
                                    должен возвратить ему денежную сумму,
                                    уплаченную потребителем по договору, за
                                    исключением расходов продавца на доставку от
                                    потребителя возвращенного товара, не позднее
                                    чем через десять дней со дня предъявления
                                    потребителем соответствующего требования;
                                </li>
                                <li className="static-page_text-list-item">
                                    Для возврата денежных средств на банковскую
                                    карту необходимо заполнить «Заявление о
                                    возврате денежных средств», которое
                                    высылается по требованию компанией на
                                    электронный адрес и оправить его вместе с
                                    приложением копии паспорта по адресу
                                    ultranout@gmail.com.
                                </li>
                                <li className="static-page_text-list-item">
                                    Возврат денежных средств будет осуществлен
                                    на банковскую карту в течение 21 (двадцати
                                    одного) рабочего дня со дня получения
                                    «Заявление о возврате денежных средств»
                                    Компанией.
                                </li>
                                <li className="static-page_text-list-item">
                                    Для возврата денежных средств по операциям
                                    проведенными с ошибками необходимо
                                    обратиться с письменным заявлением и
                                    приложением копии паспорта и
                                    чеков/квитанций, подтверждающих ошибочное
                                    списание. Данное заявление необходимо
                                    направить по адресу ultranout@gmail.com.
                                </li>
                                <li className="static-page_text-list-item">
                                    Сумма возврата будет равняться сумме
                                    покупки. Срок рассмотрения Заявления и
                                    возврата денежных средств начинает
                                    исчисляться с момента получения Компанией
                                    Заявления и рассчитывается в рабочих днях
                                    без учета праздников/выходных дней.
                                </li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>Наложенный платеж:</strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            Наложенный платеж доступен только для транспортной
                            компании http://boxberry.ru и удобен тем, что вы
                            осуществляете оплату за товар непосредственно при
                            получении товара в пункте выдачи заказа. При сумме
                            заказа свыше 5000 рублей требуется предоплата за
                            доставку!
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    По безналичному банковскому переводу без
                                    НДС:
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            По безналичному банковскому переводу (только рубли)
                            – 100% предоплата, сумма покупки увеличивается на 8%
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>
                            <h2 className="store-rules_accordion-header-text">
                                <strong>
                                    Политика возврата и обмена товара, а также
                                    возврата денежных средств:
                                </strong>
                            </h2>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p>
                                Наша компания работает в строгом соответствии с
                                действующим законодательством РФ. На все наши
                                товары распространяется политика возврата и
                                обмена.
                            </p>
                            <p>
                                Согласно статьи 26.1 закона «О защите прав
                                потребителей» вы вправе отказаться от товара,
                                приобретенного дистанционно, в любое время до
                                его передачи, а также в течение семи дней после
                                передачи товара.
                            </p>
                            <p>
                                Помните, что при возврате или обмене товара
                                надлежащего качества должны быть сохранены:
                                полная комплектация, включая пластиковые
                                конверты, прокладки и защитные плёнки, а также –
                                товарный вид изделия, товарный вид упаковки.
                                Изделие не должно иметь следов эксплуатации.
                            </p>
                            <p>
                                <strong>
                                    При выявлении недостатков у приобретенного
                                    ноутбука (или иного сложно-технического
                                    товара), магазин обязан обменять данный
                                    товар на аналогичный или вернуть деньги в
                                    течение 15 дней со дня продажи вам данного
                                    товара.
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    Если этот срок прошел, технически сложный
                                    товар можно будет обменять или вернуть
                                    только в следующих случаях:
                                </strong>
                            </p>
                            <p>
                                – продавец нарушил установленные законом сроки
                                устранения недостатков
                            </p>
                            <p>
                                – после получения товара, рассмотрения заявления
                                и подтверждения наличия заявленных дефектов,
                                возникших не по вине Покупателя, Продавец
                                обязуется возместить расходы на пересылку товара
                                в адрес магазина.
                            </p>
                            <p>
                                <strong>
                                    Условия возврата денег (в случае оплаты
                                    картой, банковским переводом и т.п.) и в
                                    какие сроки будут осуществлен возврат.
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    Особенности оплаты и доставки товара при
                                    оплате с помощью банковской карты, а также
                                    посредством электронных систем:
                                </strong>
                            </p>
                            <p>
                                – В соответствии с положением ЦБ РФ от
                                24.12.2004 №266-П «Об эмиссии банковских карт и
                                об операциях, совершаемых с использованием
                                платежных карт», операции по банковским картам
                                совершаются владельцем (держателем) банковской
                                карты.
                            </p>
                            <p>
                                – Авторизация операций по банковским картам
                                осуществляется банком. Если у банка есть
                                основания полагать, что операция носит
                                мошеннический характер, то банк вправе отказать
                                в осуществлении данной операции.
                            </p>
                            <p>
                                –&nbsp; Мошеннические операции с банковскими
                                картами попадают под действие статьи 159 УК РФ.
                            </p>
                            <p>
                                – Во избежание случаев мошенничества и
                                неправомерного использования банковских карт,
                                все заказы, оформленные на сайте и
                                предоплаченные банковской картой через
                                электронные платежные системы, проверяются
                                продавцом.
                            </p>
                            <p>
                                – Продавец оставляет за собой право без
                                объяснения причины аннулировать заказ, в том
                                числе в случае не предоставления копий указанных
                                документов клиентом в течение 7 дней с даты
                                оформления (оплаты) заказа, а также при
                                возникновении сомнений в их подлинности.
                                Стоимость заказа возвращается на карту владельца
                                в полном объеме.
                            </p>
                            <p>
                                –&nbsp; При доставке заказ вручается клиенту –
                                владельцу банковской карты, с которой была
                                произведена оплата заказа, либо его
                                представителю, при предъявлении нотариально
                                заверенной доверенности, с правом получения
                                материальных ценностей, предназначенных клиенту
                                – владельцу банковской карты.
                            </p>
                            <p>
                                – Во избежание случаев мошенничества, при
                                вручении предоплаченного заказа лицо,
                                осуществляющее доставку заказа, вправе
                                затребовать документ, удостоверяющий личность
                                получателя заказа, а также банковскую карту
                                клиента, с которой была произведена оплата
                                заказа, а в случае отказа получателя заказа
                                предоставить эти документы – отказать в выдаче
                                предоплаченного заказа до выполнения своих
                                требований.
                            </p>
                            <p>
                                – Продавец гарантирует конфиденциальность и
                                защиту персональной информации Получателя.
                            </p>
                            <p>
                                <strong>
                                    Возврат денежных средств при возврате
                                    оплаченного товара:
                                </strong>
                            </p>
                            <p>
                                При возврате ранее приобретенного товара
                                денежные средства возвращаются покупателю тем же
                                способом каким была осуществлена покупка товара.
                                При этом сроки возврата составляют:
                            </p>
                            <p>
                                – при возврате на расчетный счет (банковским
                                переводом) – 4-5 рабочих дней;
                            </p>
                            <p>
                                – при возврате на банковскую карту – перевод
                                суммы на карту осуществляется банком-эмитентом в
                                необходимый банку период времени;
                            </p>
                            <p>
                                * Для покупателей из Москвы и Московской области
                                возврат товара осуществляется через магазин
                                нашего розничного представителя по адресу:
                                127018, г. Москва, ул. Сущевский вал, д. 5 стр.
                                1А, ТК “Савеловский” пав. F-52
                            </p>
                            <p>
                                * Для покупателей из регионов России возврат
                                инициализируется по заявлению, копию заявление
                                нужно отправить на e-mail: ultranout@gmail.com и
                                приложить к посылке, отправленной в отделение
                                СДЕК, согласованное с нашим менеджером
                            </p>
                            <p>Образец заявления на обмен или возврат товара</p>
                            <p>
                                Доставка товара до нашего магазина (сервисного
                                центра), а также его доставка до покупателя
                                обратно, осуществляется силами заявителя
                                (Покупателя). После получения товара,
                                рассмотрения заявления и подтверждения наличия
                                заявленных дефектов, возникших не по вине
                                Покупателя, продавцом производится устранение
                                возникших дефектов или неполадок нашим сервисным
                                центром или авторизованным сервисным центром
                                производителя товара.
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className="static-page_text-plain">
                    <p>
                        <strong>
                            Контактные данные по вопросам, относящихся к
                            возврату и обмену товара.
                        </strong>
                    </p>
                    <p>
                        <strong>Телефон: +7 (499) 110-77-67</strong>
                    </p>
                    <p>
                        <strong>E-</strong>
                        <strong>mail:&nbsp;</strong>
                        <a href="mailto:ultranout@gmail.com">
                            <strong>ultranout@</strong>
                            <strong>gmail.</strong>
                            <strong>com</strong>
                        </a>
                    </p>
                    <p>
                        <em>
                            Обращаем ваше внимание на то, что данный
                            интернет-сайт https://ultranout.ru носит
                            исключительно информационный характер и ни при каких
                            условиях не является публичной офертой, определяемой
                            положениями Статьи 437 п.2 Гражданского кодекса
                            Российской Федерации. Для получения подробной
                            информации о технических характеристиках и стоимости
                            указанных товаров и (или) услуг, пожалуйста,
                            обращайтесь к администрации сайта по
                            телефонам:&nbsp;
                        </em>
                        <strong>
                            +<em>7 (499) 110-77-67</em>
                        </strong>
                    </p>
                    <p>
                        <strong>
                            <u>НАЗВАНИЕ ОРГАНИЗАЦИИ, АДРЕС, НОМЕР ТЕЛЕФОНА:</u>
                        </strong>
                    </p>
                    <p>
                        <strong>Телефон</strong>: +7 (499) 110-77-67
                    </p>
                    <p>
                        <strong>E-mail:</strong>&nbsp;
                        <a href="mailto:ultranout@gmail.com">
                            ultranout@gmail.com
                        </a>
                    </p>
                    <h3>Реквизиты: ИП Чеснокова Кристина Сергеевна</h3>
                    <p>ИНН: 504034065590</p>
                    <p>
                        ОГРНИП: 322911200047142
                        <br />
                        ОКПО: 2015903330
                        <br />
                        Система налогообложения: упрощённая
                        <br />
                        Юридический адрес: 359130, РЕСПУБЛИКА КАЛМЫКИЯ, РАЙОН
                        ИКИ-БУРУЛЬСКИЙ, ПОСЕЛОК ИКИ-БУРУЛ, УЛ. МИКРОРАЙОН, Д.11,
                        КВ.9
                        <br />
                        Номер счёта: 40802810029160002636
                    </p>
                    <p>
                        Банк: ФИЛИАЛ «НИЖЕГОРОДСКИЙ» АО «АЛЬФА-БАНК»
                        <br />
                        БИК: 042202824
                        <br />
                        Корреспондентский счёт: 30101810200000000824
                        <br />
                        Индивидуальный предприниматель: Чеснокова Кристина
                        Сергеевна
                    </p>
                </div>
                <div
                    className="zayava-button"
                    style={{ display: 'flex', justifyContent: 'center' }}>
                    <a
                        href="https://ultranout.ru/old/wp-content/uploads/2024/01/obrazec-zajavlenija-na-vozvrat-tovara.doc"
                        download>
                        <Button bsPrefix="btn button_main_color" size="lg">
                            ОБРАЗЕЦ ЗАЯВЛЕНИЯ НА ВОЗВРАТ
                        </Button>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PaymentOptions;
