// Компонент для отображения всей корзины
import { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/page-title/pageTitle';
import './Cart.css';
import { CartRow } from '../../components/cart-row';

function Cart() {
    // Состояние для хранения списка продуктов в корзине
    const [products, setProducts] = useState([]);

    // Эффект для получения списка продуктов из localStorage при монтировании компонента
    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem('cart')) || [];
        setProducts(cart);
    }, []);

    // Функция для удаления продукта из корзины по id
    function removeProduct(id) {
        const newProducts = products.filter((product) => product.id !== id);
        setProducts(newProducts);
        localStorage.setItem('cart', JSON.stringify(newProducts));
    }

    const handleQuantityChange = (count, product) => {
        const index = products.indexOf(product);
        const updatedProducts = [
            ...products.slice(0, index),
            {
                ...product,
                quantity: count,
            },
            ...products.slice(index + 1, products.length),
        ];
        setProducts(updatedProducts);
        localStorage.setItem('cart', JSON.stringify(updatedProducts));
    };

    // Функция для подсчета общей суммы корзины
    function getTotal() {
        return products.reduce(
            (total, product) => total + product.salePrice * product.quantity,
            0,
        );
    }

    return (
        <>
            <PageTitle title="Корзина" bottomText="Главная/Корзина" />
            <Container fluid className="cart" style={{ padding: '10px' }}>
                {/*<p style={{ textAlign: 'center' }}>*/}
                {/*    <span*/}
                {/*        style={{*/}
                {/*            textDecoration: 'underline',*/}
                {/*            color: '#0000ff',*/}
                {/*        }}>*/}
                {/*        <em>*/}
                {/*            <span style={{ fontSize: '14pt' }}>*/}
                {/*                Ввиду быстро меняющегося курса валюты,*/}
                {/*                актуальность цен и резерв просьба подтверждать у*/}
                {/*                менеджера с помощью звонка или через электронный*/}
                {/*                чат*/}
                {/*            </span>*/}
                {/*        </em>*/}
                {/*    </span>*/}
                {/*</p>*/}
                <Row>
                    <Col md={8}>
                        <h2>Корзина</h2>
                        {products.length > 0 ? (
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Товар</th>
                                        <th>Цена</th>
                                        <th>Количество</th>
                                        <th>Сумма</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products.map((product) => (
                                        <CartRow
                                            key={product.id}
                                            product={product}
                                            onQuantityChange={
                                                handleQuantityChange
                                            }
                                            onRemove={removeProduct}
                                        />
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <p>Корзина пуста</p>
                        )}
                    </Col>
                    <Col md={4}>
                        <h2>СУММА ЗАКАЗОВ</h2>
                        <hr />
                        <p className="cart-total">
                            Итого:{' '}
                            <span className="cart-total-amount">
                                {getTotal()} руб.
                            </span>
                        </p>
                        {!!products[0] ? (
                            <Link to="/checkout">
                                <Button bsPrefix="btn button_main_color">
                                    Оформить заказ
                                </Button>
                            </Link>
                        ) : (
                            <div></div>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Cart;
