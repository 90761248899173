//Карточка продукта в каталоге
import styles from './product-card.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { ModalOnAddToCart } from '../modal-on-add-to-cart/modalOnAddToCart';
import ProductButtons from '../product-buttons/productButtons';
import { clsx } from 'clsx';
import "@tcb-web/create-credit/button"

const ProductCard = ({
    product,
    verticalMode,
    hideDescription,
    onCartClick,
    isInWishlist,
    isInCart,
    isInCompare,
    onWishlistClick,
    onCompareClick,
}) => {
    const productLink = `/product/${product.sku}`;
    const [showModal, setShowModal] = useState(false);

    return (
        <div
            className={clsx(
                styles.productCard,
                verticalMode && styles.verticalMode,
            )}>
            {showModal && (
                <ModalOnAddToCart
                    show={showModal}
                    setShowModal={setShowModal}
                />
            )}
            <div className="product-card_image">
                <Link to={productLink}>
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        src={product.images[0]?.src}
                        alt="Изображение продукта"
                    />
                </Link>
            </div>
            <div
                className="product-card_text"
                style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: !verticalMode && '60%',
                    paddingLeft: '10px',
                    textAlign: verticalMode && 'center',
                }}>
                <div className="product-card_text-name">
                    <Link
                        to={productLink}
                        style={{
                            textDecoration: 'none',
                            color: 'rgb(51 51 51)',
                        }}>
                        {verticalMode ? (
                            <h6>{product.name}</h6>
                        ) : (
                            <h3>{product.name}</h3>
                        )}
                    </Link>
                </div>
                <div className="product-card_text-category">
                    <Link
                        to={productLink}
                        style={{
                            textDecoration: 'none',
                            color: 'rgb(51 51 51)',
                        }}>
                        {verticalMode ? (
                            <label
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '13px',
                                }}>
                                {product.category.name}
                            </label>
                        ) : (
                            <h6>{product.category.name}</h6>
                        )}
                    </Link>
                </div>
                <div className="product-card_text-articul">
                    <strong>Артикул:</strong> {product.sku}
                </div>
                <OverlayTrigger
                    overlay={
                        <Tooltip>Цена актуальна при оплате наличными</Tooltip>
                    }
                    placement={'top'}>
                    <div
                        className="product-card_text-price"
                        style={{
                            display: 'flex',
                            justifyContent: verticalMode && 'center',
                            fontWeight: '600',
                            fontSize: !verticalMode && '20px',
                        }}>
                        <div className="product-card_text-price crossed">
                            <del
                                style={{
                                    color: 'rgb(245 102 34)',
                                    fontSize: '18px',
                                    fontWeight: '400',
                                }}>
                                <span style={{ color: 'black' }}>
                                    {Number(
                                        product.regularPrice,
                                    ).toLocaleString('en-US')}{' '}
                                    &#8381;
                                </span>
                            </del>
                        </div>
                        <div
                            className="product-card_text-price discount"
                            style={{
                                color: 'rgb(245 102 34)',
                                marginLeft: '5px',
                            }}>
                            {Number(product.salePrice).toLocaleString('en-US')}{' '}
                            &#8381;
                        </div>
                    </div>
                </OverlayTrigger>
                <div
                    style={{
                        width: '100%',
                        maxHeight: '100%',
                        paddingTop: '5px',
                        color: 'rgb(119 119 119)',
                        fontSize: '14px',
                    }}
                    className={`product-card_text-description ${
                        hideDescription && 'hide-description'
                    }`}
                    dangerouslySetInnerHTML={{
                        __html: !verticalMode
                            ? product.shortDescription
                            : product.metaData.find(
                                  (element) => element.key === 'my_undertitle',
                              ).value,
                    }}></div>
                <ProductButtons
                    product={product}
                    inCart={isInCart}
                    onClickCart={() => {
                        onCartClick(product);
                        setShowModal(true);
                    }}
                    onInstantClick={() => {
                        onCartClick(product);
                    }}
                    inWishlist={isInWishlist}
                    onClickWishlist={() => onWishlistClick(product)}
                    isInCompare={isInCompare}
                    onClickCompare={onCompareClick}
                />
                {/*<InstantPurchaseButton product={product} onClick={onCartClick} />*/}
                <div
                    className="product-card_stock-quantity"
                    style={{
                        paddingTop: '10px',
                        fontWeight: '700',
                        fontSize: '14px',
                    }}>
                    {product.stockQuantity > 0 ? (
                        <p>В наличии: {product.stockQuantity}</p>
                    ) : (
                        <p>Доступно для предзаказа</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
