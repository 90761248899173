export const handleWishlistClick = (product, wishlist, setWishlist) => {
    // Проверяем, есть ли продукт уже в списке
    const index = wishlist.indexOf(wishlist.find((item) => item.id === product.id));
    //Метод indexOf возвращает -1 если не находит элемент
    if (index === -1) {
        // Добавляем продукт в конец массива
        const newWishlist = [...wishlist, {...product}];
        // Обновляем состояние
        setWishlist(newWishlist);
        // Обновляем localStorage
        localStorage.setItem("wishlist", JSON.stringify(newWishlist));
        window.dispatchEvent(new Event('storage'));
    }
    else {
        //Создаем новый массив без этого элемента
        const newWishlist = [...wishlist.slice(0, index), ...wishlist.slice(index + 1, wishlist.length)]
        // Обновляем состояние
        setWishlist(newWishlist);
        // Обновляем localStorage
        localStorage.setItem("wishlist", JSON.stringify(newWishlist));
        window.dispatchEvent(new Event('storage'));
    }
}
