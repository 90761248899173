import { Formik } from 'formik';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  Row, Spinner,
} from 'react-bootstrap';
import * as yup from 'yup';
import useScript from '../../helpers/hooks/useScript';
import axios from 'axios';
import { useState } from 'react';

const phoneNumberRegEx =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

const schema = yup.object().shape({
    name: yup.string().required('Это обязательное поле'),
    phone: yup
        .string()
        .required('Это обязательное поле')
        .matches(phoneNumberRegEx, 'Неправильный номер телефона'),
    email: yup
        .string()
        .required('Это обязательное поле')
        .email('Неправильный email'),
});

const ContactFormModal = ({ show, setShowModal }) => {
    useScript('https://smtpjs.com/v3/smtp.js')
    const [isLoading, setIsLoading] = useState(false )

    return (
        <Modal show={show} onHide={() => setShowModal(false)} centered>
            <ModalBody>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        textAreaInput: '',
                    }}
                    onSubmit={(values, formikHelpers) => {
                        setIsLoading(true);
                        axios
                            .post(`${process.env.REACT_APP_API_URL}/emails/contactUs`, {
                                data: values,
                            })
                            .then((response) => {
                                alert(response.data);
                                setIsLoading(false);
                                response.status === 200 && setShowModal(false);
                            });
                        formikHelpers.setSubmitting(false);
                    }}
                    validationSchema={schema}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <Form
                            onBlur={handleBlur}
                            noValidate={true}
                            onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Имя</Form.Label>
                                        <Form.Control
                                            placeholder="Имя"
                                            name="name"
                                            value={values.name}
                                            disabled={isLoading}
                                            onChange={
                                                handleChange
                                            }></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            name="email"
                                            value={values.email}
                                            disabled={isLoading}
                                            onChange={handleChange}
                                            isValid={
                                                touched.email && !errors.email
                                            }
                                            isInvalid={
                                                !!errors.email
                                            }></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Телефон</Form.Label>
                                        <Form.Control
                                            type="phone"
                                            placeholder="Телефон"
                                            name="phone"
                                            value={values.phone}
                                            disabled={isLoading}
                                            onChange={handleChange}
                                            isValid={
                                                touched.phone && !errors.phone
                                            }
                                            isInvalid={
                                                !!errors.phone
                                            }></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.phone}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Ваш вопрос"
                                            name="textAreaInput"
                                            value={values.textAreaInput}
                                            disabled={isLoading}
                                            onChange={
                                                handleChange
                                            }></Form.Control>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Button type="submit" variant="dark" style={{width: '100%'}}>
                                            {isLoading ? <Spinner size='sm'/> : 'Отправить'}
                                        </Button>
                                        <Form.Label
                                            style={{
                                                fontSize: '0.875em',
                                                color: 'gray',
                                            }}>
                                            Ваше обращение будет отправлено на
                                            почту: ultranout@gmail.com
                                        </Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ContactFormModal;
